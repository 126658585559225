/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
// @mui/icons-material

// core components
import { styled } from '@mui/material/styles';
import { cardStyle, classes } from "../../../assets/jss/material-kit-pro-react/components/cardStyle.jsx";
const CardRoot = styled('div')(({ theme }) => cardStyle(theme));

function Card({ ...props }) {
    const {
      className,
      children,
      plain,
      profile,
      blog,
      raised,
      background,
      pricing,
      color,
      product,
      testimonial,
      chart,
      login,
      ...rest
    } = props;
    const cardClasses = classNames({
      [classes.card]: true,
      [classes.cardPlain]: plain,
      [classes.cardProfile]: profile || testimonial,
      [classes.cardBlog]: blog,
      [classes.cardRaised]: raised,
      [classes.cardBackground]: background,
      [classes.cardPricingColor]:
        (pricing && color !== undefined) || (pricing && background !== undefined),
      [classes[color]]: color,
      [classes.cardPricing]: pricing,
      [classes.cardProduct]: product,
      [classes.cardChart]: chart,
      [classes.cardLogin]: login,
      [className]: className !== undefined
    });
    return (
      <CardRoot className={cardClasses} {...rest}>
        {children}
      </CardRoot>
    );
  }
  
  Card.propTypes = {
    className: PropTypes.string,
    plain: PropTypes.bool,
    profile: PropTypes.bool,
    blog: PropTypes.bool,
    raised: PropTypes.bool,
    background: PropTypes.bool,
    pricing: PropTypes.bool,
    testimonial: PropTypes.bool,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "rose"
    ]),
    product: PropTypes.bool,
    chart: PropTypes.bool,
    login: PropTypes.bool,
    children: PropTypes.node
  };
  
  export default Card;
