import Cookie from './app/assets/js/utils/Cookie';

const Config = {
    REACT_APP_FB_APP_ID: null,
    REACT_APP_GOOGLE_CLIENT_ID: null,
    REACT_APP_MOGLI_AWS_API_KEY: null,
    REACT_APP_GOOGLE_ANALYTICS_ID: null,
    REACT_APP_LOG_LEVEL: null,
    REACT_APP_CLOUDFRONT_IMAGES: null,
    REACT_APP_INSTAGRAM_API_APP_ID: null,
    REACT_APP_CHROME_EXT: null,
    REACT_APP_SMS_SUPPORT_LINK: null,
    REACT_APP_ADMIN_URL: null,
    REACT_APP_FULLSTORY: null,
    REACT_APP_SF_OBJECT_TYPE_NAME: null,
    REACT_APP_ENCRYPTION_KEY: null,
    REACT_APP_MOGLI_AWS_COGNITO_WEB_CLIENT_ID: null,
    REACT_APP_MOGLI_AWS_COGNITO_WEB_CLIENT_SECRET: null,
    REACT_APP_MOGLI_AWS_COGNITO_NATIVE_CLIENT_ID: null,
    REACT_APP_MOGLI_AWS_COGNITO_IDENTITY_POOL_ID: null,
    REACT_APP_REGION: null,
    REACT_APP_MOGLI_AWS_COGNITO_USER_POOL_ID: null,
    REACT_APP_MOGLI_AWS_ENDPOINT: null,
    REACT_APP_MOGLI_AWS_COGNITO_CUSTOM_DOMAIN: null,
    REACT_APP_PRO_CLIENT_SECRET: null,
    REACT_APP_PRO_CLIENT_ID: null,
    REACT_APP_GOOGLEAPIS_CLIENTID: null,
    REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY: null,
    REACT_APP_MOGLI_AWS_COGNITO_HOSTED_AUTH: null,
    REACT_APP_SIGNUP_DETECT_BOTS: null,
    REACT_APP_ADMIN_AUTH_KEY: null,
    REACT_APP_PRIMARY: null,
    REACT_APP_PRIMARY_SITE_URL: null,
    REACT_APP_KEYWORDS: null,
    REACT_APP_MENU_TITLE: null,
    REACT_APP_TITLE: null,
    REACT_APP_MATERIAL_GRID_KEY: null,
    REACT_APP_PLATFORM_TYPE: null,
    REACT_APP_SF_AUTH_URL: null,
    REACT_APP_SF_CLIENT_ID: null,
    populateConfig(config){
        if(typeof(config) !== "object"){
            return;
        }
        for(const key in config){
            let value = config[key];
            if(typeof(value) === "string"){
                this[key] = value;
            }
        }
    },
    getClientId(){
        try{
            if(this.REACT_APP_MOGLI_AWS_COGNITO_WEB_CLIENT_ID){
                return this.REACT_APP_MOGLI_AWS_COGNITO_WEB_CLIENT_ID;
            }
            if(process.env.REACT_APP_MOGLI_AWS_COGNITO_WEB_CLIENT_ID){
                return process.env.REACT_APP_MOGLI_AWS_COGNITO_WEB_CLIENT_ID;
            }
        }catch(e){  }
        return "";
    },
    getClientSecret(){
        try{
            if(this.REACT_APP_MOGLI_AWS_COGNITO_WEB_CLIENT_SECRET){
                return this.REACT_APP_MOGLI_AWS_COGNITO_WEB_CLIENT_SECRET;
            }
            if(process.env.REACT_APP_MOGLI_AWS_COGNITO_WEB_CLIENT_SECRET){
                return process.env.REACT_APP_MOGLI_AWS_COGNITO_WEB_CLIENT_SECRET;
            }
        }catch(e){  }
        return "";
    },
    getNativeClientId(){
        try{
            if(this.REACT_APP_MOGLI_AWS_COGNITO_NATIVE_CLIENT_ID){
                return this.REACT_APP_MOGLI_AWS_COGNITO_NATIVE_CLIENT_ID;
            }
            if(process.env.REACT_APP_MOGLI_AWS_COGNITO_NATIVE_CLIENT_ID){
                return process.env.REACT_APP_MOGLI_AWS_COGNITO_NATIVE_CLIENT_ID;
            }
        }catch(e){  }
        return "";
    },
    getAuthUrl(){
        try{
            if(this.REACT_APP_MOGLI_AWS_COGNITO_CUSTOM_DOMAIN){
                return this.REACT_APP_MOGLI_AWS_COGNITO_CUSTOM_DOMAIN;
            }
            if(process.env.REACT_APP_MOGLI_AWS_COGNITO_CUSTOM_DOMAIN){
                return process.env.REACT_APP_MOGLI_AWS_COGNITO_CUSTOM_DOMAIN;
            }
        }catch(e){  }
        return "";
    },
    getApiUrl(){
        try{
            if(this.REACT_APP_MOGLI_AWS_ENDPOINT){
                return this.REACT_APP_MOGLI_AWS_ENDPOINT;
            }
            if(process.env.REACT_APP_MOGLI_AWS_ENDPOINT){
                return process.env.REACT_APP_MOGLI_AWS_ENDPOINT;
            }
        }catch(e){  }
        return "";
    },
    getRedirectUri(returnUrl = ""){
        return window.location.origin+returnUrl;
    },
    getLoginUrl(returnUrl = ""){
        const CLIENT_ID = this.getClientId();
        const AUTH_URL = this.getAuthUrl()+"login?response_type=code&scope=openid&client_id="+CLIENT_ID+"&redirect_uri="+this.getRedirectUri(returnUrl);
        return AUTH_URL;
    },
    getSignupUrl(returnUrl = ""){
        const CLIENT_ID = this.getClientId();
        const AUTH_URL = this.getAuthUrl()+"signup?response_type=code&scope=openid&client_id="+CLIENT_ID+"&redirect_uri="+this.getRedirectUri(returnUrl);
        return AUTH_URL;
    },
    getLogoutUrl(){
        const CLIENT_ID = this.getClientId();
        const AUTH_URL = this.getAuthUrl()+"logout?client_id="+CLIENT_ID+"&logout_uri="+this.getRedirectUri()+"/logout";
        return AUTH_URL;
    },
    getTokenUrl(){
        const AUTH_URL = this.getAuthUrl()+"oauth2/token";
        return AUTH_URL;
    },
    getUserInfoUrl(){
        const AUTH_URL = this.getApiUrl()+"data/user/me";
        return AUTH_URL;
    },
    getProvierClientId(){
        try{
            if(this.REACT_APP_PRO_CLIENT_ID){
                return this.REACT_APP_PRO_CLIENT_ID;
            }
            if(process.env.REACT_APP_PRO_CLIENT_ID){
                return process.env.REACT_APP_PRO_CLIENT_ID;
            }
        }catch(e){  }
        return "";
    },
    getProviderClientSecret(){
        try{
            if(this.REACT_APP_PRO_CLIENT_SECRET){
                return this.REACT_APP_PRO_CLIENT_SECRET;
            }
            if(process.env.REACT_APP_PRO_CLIENT_SECRET){
                return process.env.REACT_APP_PRO_CLIENT_SECRET;
            }
        }catch(e){  }
        return "";
    },
    getProviderLoginUrl(provider){
        const CLIENT_ID = this.getProvierClientId();
        const AUTH_URL = this.getAuthUrl()+"oauth2/authorize?response_type=code&scope=openid&client_id="+CLIENT_ID+"&redirect_uri="+this.getRedirectUri()+"&identity_provider="+provider;
        return AUTH_URL;
    },
    getGoogleAnalyticsId(){
        try{
            if(this.REACT_APP_GOOGLE_ANALYTICS_ID){
                return this.REACT_APP_GOOGLE_ANALYTICS_ID;
            }
            if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID){
                return process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
            }
        }catch(e){  }
        return "";
    },
    getXApiKey(){
        try{
            if(this.REACT_APP_MOGLI_AWS_API_KEY){
                return this.REACT_APP_MOGLI_AWS_API_KEY;
            }
            if(process.env.REACT_APP_MOGLI_AWS_API_KEY){
                return process.env.REACT_APP_MOGLI_AWS_API_KEY;
            }
        }catch(e){  }
        return "";
    },
    getFbAppId(){
        try{
            if(this.REACT_APP_FB_APP_ID){
                return this.REACT_APP_FB_APP_ID;
            }
            if(process.env.REACT_APP_FB_APP_ID){
                return process.env.REACT_APP_FB_APP_ID;
            }
        }catch(e){  }
        return "";
    },
    getGoogleClientId(){
        try{
            if(this.REACT_APP_GOOGLE_CLIENT_ID){
                return this.REACT_APP_GOOGLE_CLIENT_ID;
            }
            if(process.env.REACT_APP_GOOGLE_CLIENT_ID){
                return process.env.REACT_APP_GOOGLE_CLIENT_ID;
            }
        }catch(e){  }
        return "";
    },
    getUserPoolId(){
        try{
            if(this.REACT_APP_MOGLI_AWS_COGNITO_USER_POOL_ID){
                return this.REACT_APP_MOGLI_AWS_COGNITO_USER_POOL_ID;
            }
            if(process.env.REACT_APP_MOGLI_AWS_COGNITO_USER_POOL_ID){
                return process.env.REACT_APP_MOGLI_AWS_COGNITO_USER_POOL_ID;
            }
        }catch(e){  }
        return "";
    },
    getRegion(){
        try{
            if(this.REACT_APP_REGION){
                return this.REACT_APP_REGION;
            }
            if(process.env.REACT_APP_REGION){
                return process.env.REACT_APP_REGION;
            }
        }catch(e){  }
        return "";
    },
    getIdentityPoolId(){
        try{
            if(this.REACT_APP_MOGLI_AWS_COGNITO_IDENTITY_POOL_ID){
                return this.REACT_APP_MOGLI_AWS_COGNITO_IDENTITY_POOL_ID;
            }
            if(process.env.REACT_APP_MOGLI_AWS_COGNITO_IDENTITY_POOL_ID){
                return process.env.REACT_APP_MOGLI_AWS_COGNITO_IDENTITY_POOL_ID;
            }
        }catch(e){  }
        return "";
    },
    getLogType(){
        try{
            if(process.env.REACT_APP_LOG_LEVEL){
                return process.env.REACT_APP_LOG_LEVEL;
            }
        }catch(e){  }
        return this.REACT_APP_LOG_LEVEL;
    },
    getApiHeaders(){
        let id_token = Cookie.read("mogliAppIdToken");
        if(!id_token || id_token === null){
            id_token = "anonymous";
        }
        let audience = Config.getNativeClientId();
        const federated = Cookie.read("federated");
        if(federated && parseInt(federated) === 1){
            audience = Config.getClientId();
        }
        const headers = {
            // 'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': document.location.origin,
            'Authorization': id_token,
            'x-api-key': this.getXApiKey(),
            'Auth_Audience': audience,
            // 'GG_Ids': Cookie.getCookieData()
        };
        // if(localStorage.getItem("auth_identity") !== null){
        //     headers['Auth_Identity'] = localStorage.getItem("auth_identity");
        // }
        return headers;
    },
    getCdnUrl(){
        try{
            if(this.REACT_APP_CLOUDFRONT_IMAGES){
                return this.REACT_APP_CLOUDFRONT_IMAGES;
            }
            if(process.env.REACT_APP_CLOUDFRONT_IMAGES){
                return process.env.REACT_APP_CLOUDFRONT_IMAGES;
            }
        }catch(e){  }
        return "";
    },
    getInstagramAppId(){
        try{
            if(this.REACT_APP_INSTAGRAM_API_APP_ID){
                return this.REACT_APP_INSTAGRAM_API_APP_ID;
            }
            if(process.env.REACT_APP_INSTAGRAM_API_APP_ID){
                return process.env.REACT_APP_INSTAGRAM_API_APP_ID;
            }
        }catch(e){  }
        return "";
    },
    getInstagramAuthUrl(){
        const CLIENT_ID = this.getInstagramAppId();
        const AUTH_URL = "https://www.instagram.com/oauth/authorize?client_id="+CLIENT_ID+"&scope=user_profile,user_media&response_type=code&redirect_uri="+this.getRedirectUri()+"/dashboard/platformwizard/instagramlink";
        return AUTH_URL;
    },
    getGoogleApisClientId(){
        try{
            if(this.REACT_APP_GOOGLEAPIS_CLIENTID){
                return this.REACT_APP_GOOGLEAPIS_CLIENTID;
            }
            if(process.env.REACT_APP_GOOGLEAPIS_CLIENTID){
                return process.env.REACT_APP_GOOGLEAPIS_CLIENTID;
            }
        }catch(e){  }
        return "";
    },
    getRecaptchaKey(){
        try{
            if(this.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY){
                return this.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
            }
            if(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY){
                return process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
            }
        }catch(e){  }
        return "";
    },
    isRecaptchaEnabled(){
        const key = this.getRecaptchaKey();
        if(!key || typeof(key) !== "string"){
            return false;
        }
        if(key.length <= 5){
            return false;
        }
        return true;
    },
    getAmazonLoginUrl(returnUrl){
        const CLIENT_ID = this.getClientId();
        const AUTH_URL = this.getAuthUrl()+"oauth2/authorize?identity_provider=LoginWithAmazon&response_type=code&scope=openid&client_id="+CLIENT_ID+"&redirect_uri="+this.getRedirectUri(returnUrl);
        return AUTH_URL;
    },
    getGoogleLoginUrl(returnUrl){
        const CLIENT_ID = this.getClientId();
        const AUTH_URL = this.getAuthUrl()+"oauth2/authorize?identity_provider=Google&response_type=code&scope=openid&client_id="+CLIENT_ID+"&redirect_uri="+this.getRedirectUri(returnUrl);
        return AUTH_URL;
    },
    getFacebookLoginUrl(returnUrl){
        const CLIENT_ID = this.getClientId();
        const AUTH_URL = this.getAuthUrl()+"oauth2/authorize?identity_provider=Facebook&response_type=code&scope=openid&client_id="+CLIENT_ID+"&redirect_uri="+this.getRedirectUri(returnUrl);
        return AUTH_URL;
    },
    getSupportUrl(){
        try{
            if(this.REACT_APP_SMS_SUPPORT_LINK){
                return this.REACT_APP_SMS_SUPPORT_LINK;
            }
            if(process.env.REACT_APP_SMS_SUPPORT_LINK){
                return process.env.REACT_APP_SMS_SUPPORT_LINK;
            }
        }catch(e){  }
        return "";
    },
    isHostedAuthEnabled(){
        try{
            if(this.REACT_APP_MOGLI_AWS_COGNITO_HOSTED_AUTH && (this.REACT_APP_MOGLI_AWS_COGNITO_HOSTED_AUTH === "true" || this.REACT_APP_MOGLI_AWS_COGNITO_HOSTED_AUTH === true)){
                return true;
            }
            if(process.env.REACT_APP_MOGLI_AWS_COGNITO_HOSTED_AUTH && process.env.REACT_APP_MOGLI_AWS_COGNITO_HOSTED_AUTH === "true"){
                return true;
            }
            return false;
        }catch(e){  }
        return false;
    },
    isHoneypotEnabled(){
        try{
            if(this.REACT_APP_SIGNUP_DETECT_BOTS && (this.REACT_APP_SIGNUP_DETECT_BOTS === "true" || this.REACT_APP_SIGNUP_DETECT_BOTS === true)){
                return true;
            }
            if(process.env.REACT_APP_SIGNUP_DETECT_BOTS && process.env.REACT_APP_SIGNUP_DETECT_BOTS === "true"){
              return true;
            }
            return false;
        }catch(e){  }
        return false;
    },
    getAdminUrl(){
        try{
            if(this.REACT_APP_ADMIN_URL){
                return this.REACT_APP_ADMIN_URL;
            }
            if(process.env.REACT_APP_ADMIN_URL){
                return process.env.REACT_APP_ADMIN_URL;
            }
        }catch(e){  }
        return "";
    },
    getFullStoryId(){
        try{
            if(this.REACT_APP_FULLSTORY){
                return this.REACT_APP_FULLSTORY;
            }
            if(process.env.REACT_APP_FULLSTORY){
                return process.env.REACT_APP_FULLSTORY;
            }
        }catch(e){  }
        return "";
    },
    getSfObjectTypeName(){
        try{
            if(this.REACT_APP_SF_OBJECT_TYPE_NAME){
                return this.REACT_APP_SF_OBJECT_TYPE_NAME;
            }
            if(process.env.REACT_APP_SF_OBJECT_TYPE_NAME){
                return process.env.REACT_APP_SF_OBJECT_TYPE_NAME;
            }
        }catch(e){  }
        return ;
    },
    getAdminAuthKey(){
        try{
            if(this.REACT_APP_ADMIN_AUTH_KEY){
                return this.REACT_APP_ADMIN_AUTH_KEY;
            }
            if(process.env.REACT_APP_ADMIN_AUTH_KEY){
                return process.env.REACT_APP_ADMIN_AUTH_KEY;
            }
        }catch(e){  }
        return "anonymous";
    },
    isPrimaryApp(){
        try{
            if(this.REACT_APP_PRIMARY && (this.REACT_APP_PRIMARY === "true" || this.REACT_APP_PRIMARY === true)){
                return true;
            }
            if(process.env.REACT_APP_PRIMARY && process.env.REACT_APP_PRIMARY === "true"){
                return true;
            }
        }catch(e){  }
        return false;
    },
    getPrimarySiteUrl(){
        try{
            if(this.REACT_APP_PRIMARY_SITE_URL){
                return this.REACT_APP_PRIMARY_SITE_URL;
            }
            if(process.env.REACT_APP_PRIMARY_SITE_URL){
              return process.env.REACT_APP_PRIMARY_SITE_URL;
            }
        }catch(e){  }
        return null;
    },
    getUpscriptSiteUrl(){
        try{
            if(this.REACT_APP_UPSCRIPT_SITE_URL){
                return this.REACT_APP_UPSCRIPT_SITE_URL;
            }
            if(process.env.REACT_APP_UPSCRIPT_SITE_URL){
              return process.env.REACT_APP_UPSCRIPT_SITE_URL;
            }
        }catch(e){  }
        return null;
    },
    getEncryptionKey(){
        try{
            if(this.REACT_APP_ENCRYPTION_KEY){
                return this.REACT_APP_ENCRYPTION_KEY;
            }
            if(process.env.REACT_APP_ENCRYPTION_KEY){
                return process.env.REACT_APP_ENCRYPTION_KEY;
            }
        }catch(e){  }
        return "";
    },
    getKeywords(){
        try{
            if(this.REACT_APP_KEYWORDS){
                return this.REACT_APP_KEYWORDS;
            }
            if(process.env.REACT_APP_KEYWORDS){
                return process.env.REACT_APP_KEYWORDS;
            }
        }catch(e){  }
        return "";
    },
    getAppTitle(){
        try{
            if(this.REACT_APP_TITLE){
                return this.REACT_APP_TITLE;
            }
            if(process.env.REACT_APP_TITLE){
                return process.env.REACT_APP_TITLE;
            }
        }catch(e){  }
        return "";
    },
    getAppDescription(){
        try{
            if(this.REACT_APP_DESCRIPTION){
                return this.REACT_APP_DESCRIPTION;
            }
            if(process.env.REACT_APP_DESCRIPTION){
                return process.env.REACT_APP_DESCRIPTION;
            }
        }catch(e){  }
        return "";
    },
    getMenuTitle(){
        try{
            if(this.REACT_APP_MENU_TITLE){
                return this.REACT_APP_MENU_TITLE;
            }
            if(process.env.REACT_APP_MENU_TITLE){
                return process.env.REACT_APP_MENU_TITLE;
            }
        }catch(e){  }
        return "";
    },
    getMaterialUiGridLicenseKey(){
        try{
            if(this.REACT_APP_MATERIAL_GRID_KEY){
                return this.REACT_APP_MATERIAL_GRID_KEY;
            }
            if(process.env.REACT_APP_MATERIAL_GRID_KEY){
                return process.env.REACT_APP_MATERIAL_GRID_KEY;
            }
        }catch(e){  }
        return "";
    },
    getPharmacyEmail(){
        try{
            if(this.REACT_APP_PHARMACY_EMAIL){
                return this.REACT_APP_PHARMACY_EMAIL;
            }
            if(process.env.REACT_APP_PHARMACY_EMAIL){
                return process.env.REACT_APP_PHARMACY_EMAIL;
            }
        }catch(e){  }
        return "";
    },
    getAppThemeType(){
        try{
            if(this.REACT_APP_PLATFORM_TYPE){
                return this.REACT_APP_PLATFORM_TYPE;
            }
            if(process.env.REACT_APP_PLATFORM_TYPE){
                return process.env.REACT_APP_PLATFORM_TYPE;
            }
        }catch(e){  }
        return null;
    },
    getSFAuthUrl(){
        try{
            if(this.REACT_APP_SF_AUTH_URL){
                return this.REACT_APP_SF_AUTH_URL;
            }
            if(process.env.REACT_APP_SF_AUTH_URL){
                return process.env.REACT_APP_SF_AUTH_URL;
            }
        }catch(e){  }
        return "";
    },
    getSFClientId(){
        try{
            if(this.REACT_APP_SF_CLIENT_ID){
                return this.REACT_APP_SF_CLIENT_ID;
            }
            if(process.env.REACT_APP_SF_CLIENT_ID){
                return process.env.REACT_APP_SF_CLIENT_ID;
            }
        }catch(e){  }
        return "";
    },
    getSFLoginUrl(domain = null, returnUrl = "", CLIENT_ID = null){
        if(domain === null){
            domain = this.getSFAuthUrl();
        }
        if(CLIENT_ID === null){
            CLIENT_ID = this.getSFClientId();
        }
        const AUTH_URL = domain+"services/oauth2/authorize?response_type=code&client_id="+CLIENT_ID+"&redirect_uri="+this.getRedirectUri(returnUrl);
        return AUTH_URL;
    },
};

let settings = localStorage.getItem("mogli_settings");
if(settings !== null){
    try{
        settings = JSON.parse(settings);
        if(typeof(settings) === "object" && settings.hasOwnProperty("hhc1-bd-react")){
            Config.populateConfig(settings['hhc1-bd-react']);
        }
    }catch(e){ }
}

export default Config;