/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @mui/material components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Hidden from "@mui/material/Hidden";

// material-ui icons
import Menu from "@mui/icons-material/Menu";
import MoreVert from "@mui/icons-material/MoreVert";
import ViewList from "@mui/icons-material/ViewList";

// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "../CustomButtons/Button.jsx";
import { styled } from '@mui/material/styles';
import { adminNavbarStyle, classes } from "../../../assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.jsx";
const AdminNavbarRoot = styled('div')(({ theme }) => adminNavbarStyle(theme));

function AdminNavbar({ ...props }) {
    const { color, rtlActive, brandText, store } = props;
    const appBarClasses = cx({
        [" " + classes[color]]: color
    });
    const sidebarMinimize =
        classes.sidebarMinimize +
        " " +
        cx({
            [classes.sidebarMinimizeRTL]: rtlActive
        });
    return (
        <AdminNavbarRoot>
            <AppBar className={classes.appBar + appBarClasses}>
                <Toolbar className={classes.container}>
                    <Hidden smDown implementation="css">
                        <div className={sidebarMinimize}>
                            {props.miniActive ? (
                            <Button
                                justIcon
                                round
                                color="white"
                                onClick={props.sidebarMinimize}
                            >
                                <ViewList className={classes.sidebarMiniIcon} />
                            </Button>
                            ) : (
                            <Button
                                justIcon
                                round
                                color="white"
                                onClick={props.sidebarMinimize}
                            >
                                <MoreVert className={classes.sidebarMiniIcon} />
                            </Button>
                            )}
                        </div>
                    </Hidden>
                    <div className={classes.flex}>
                        {/* Here we create navbar brand, based on route name */}
                        <Button href="#" className={classes.title} color="transparent">
                            {brandText}
                        </Button>
                    </div>
                    <Hidden smDown implementation="css">
                        <AdminNavbarLinks rtlActive={rtlActive} store={store} />
                    </Hidden>
                    <Hidden mdUp implementation="css">
                        <Button
                            className={classes.appResponsive}
                            color="transparent"
                            justIcon
                            aria-label="open drawer"
                            onClick={props.handleDrawerToggle}
                        >
                            <Menu />
                        </Button>
                    </Hidden>
                </Toolbar>
            </AppBar>
        </AdminNavbarRoot>
    );
}

AdminNavbar.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    rtlActive: PropTypes.bool,
    brandText: PropTypes.string,
    miniActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    sidebarMinimize: PropTypes.func
};

export default AdminNavbar;
