import React from 'react'
import PropTypes from "prop-types";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "../CustomButtons/Button.jsx";
import DialogActions from "@mui/material/DialogActions";
import CustomSelect from "../CustomInput/CustomSelect";
import CustomInput from "../CustomInput/CustomInput.jsx";

import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/reducers/rootReducer";
import Close from "@mui/icons-material/Close";
import { styled } from '@mui/material/styles';
import { sfConnectModalStyle, classes } from "../../../assets/jss/modals/sfConnectModalStyle.jsx";
const SFConnectModalRoot = styled(Dialog)(({ theme }) => sfConnectModalStyle(theme));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const SFConnectModal = (props) => {
    const { authorized, user } = props.state;
    const customDomainLabel = 'Custom Domain';
    const [state, setState] = React.useState({
        sfUrl: "",
        customDomain: "",
        clientId: "",
        validation: {
            sfUrl: "",
            customDomain: "",
            clientId: "",
        },
        validate: {
            sfUrl: false,
            customDomain: false,
            clientId: false,
        },
        forceValidation: false,
    });
    const validation = validateForm(state.forceValidation);
    function handleChange(e, name) {
        let value = e.target.value;
        setState({
            ...state,
            [name]: value,
            validate: {
                ...state.validate,
                [name]: true,
            },
        });
    }
    function validateForm(force = false) {
        const { sfUrl, customDomain, validate } = state;
        if (force) {
            for (const key in validate) {
                validate[key] = true;
            }
        }
        let validation = {
            sfUrl: validate.sfUrl ? "success" : "",
            customDomain: validate.customDomain ? "success" : "",
            clientId: validate.clientId ? "success" : "",
            isValid: true,
        };
        if(validate.sfUrl && sfUrl.length <= 0){
            validation.sfUrl = "error";
            validation.isValid = false;
        }
        if(sfUrl === customDomainLabel && validate.customDomain){
            if(customDomain.length <= 0){
                validation.customDomain = "error";
                validation.isValid = false;
            }else if(!isURL(customDomain)){
                validation.customDomain = "error";
                validation.isValid = false;
            }
        }
        // if(validate.clientId && clientId.length <= 0){
        //     validation.clientId = "error";
        //     validation.isValid = false;
        // }
        return validation;
    }
    function isURL(url) {
        try{
            new URL(url);
            return true;
        }catch(e){}
        var a = document.createElement('a');
        a.href = url;
        if(a.host && a.host.length > 0 && a.host !== window.location.host){
            return true;
        }
        return false;
    }
    function onConnectOrg(){
        const validation = validateForm(true);
        if (!validation.isValid) {
            setState({
                ...state,
                forceValidation: true,
            });
            return;
        }
        var data = Object.assign({}, state);
        if(state.sfUrl === customDomainLabel){
            data['sfUrl'] = state.customDomain;
        }
        props.onSuccess(data);
    }
    return (
        <SFConnectModalRoot
            classes={{
                root: classes.modalRoot,
                paper: classes.modal
            }}
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="connectModal-modal-slide-title"
            aria-describedby="connectModal-modal-slide-description"
        >
            <DialogTitle
                id="connectModal-modal-slide-title"
                className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonLogin}
                        key="close"
                        aria-label="Close"
                        onClick={() => props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
            </DialogTitle>
            <DialogContent
                id="connectModal-modal-slide-description"
                className={classes.modalBody}
                >
                    <CustomSelect
                        formControlProps={{
                            fullWidth: true,
                        }}
                        success={validation.sfUrl === "success"}
                        error={validation.sfUrl === "error"}
                        selectProps={{
                            onChange: (e) => handleChange(e, "sfUrl"),
                            value: state.sfUrl,
                            
                        }}
                        labelText="Salesforce Environment"
                        inputProps={{
                            name: "sfUrl",
                            id: "outlined-sfUrl",
                            className: classes.alignLeft,
                        }}
                        items={['https://login.salesforce.com', 'https://test.salesforce.com', customDomainLabel]}
                    />
                    {
                        state.sfUrl === customDomainLabel ?
                            <CustomInput
                                id="input-customDomain"
                                labelText={customDomainLabel}
                                success={validation.customDomain === "success"}
                                error={validation.customDomain === "error"}
                                inputProps={{
                                    onChange: (e) => handleChange(e, "customDomain"),
                                    name: "customDomain",
                                    type: "text",
                                    value: state.customDomain,
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        :
                        null
                    }
                    {
                        authorized && user.BusinessAdmin__c ?
                            <CustomInput
                                id="input-clientId"
                                labelText="ConnectedApp ClientID"
                                // success={validation.clientId === "success"}
                                // error={validation.clientId === "error"}
                                inputProps={{
                                    onChange: (e) => handleChange(e, "clientId"),
                                    name: "clientId",
                                    type: "text",
                                    value: state.clientId,
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        :
                        null
                    }
            </DialogContent>
            <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                <Button
                    onClick={() => props.onClose()}
                    color="white"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => onConnectOrg()}
                    color="success"
                >
                    Connect Org
                </Button>
            </DialogActions>
        </SFConnectModalRoot>
    )
}

SFConnectModal.defaultProps = {
    open: false,
}
SFConnectModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
}
export default connect(mapStateToProps)(SFConnectModal);