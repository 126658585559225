import modalStyle from "./material-kit-pro-react/modalStyle.jsx";
const PREFIX = 'ManageSession';
const manageSessionStyle = theme => ({
    ...modalStyle(theme),
    [`& .${PREFIX}-modal`]: {
        ...modalStyle(theme).modal,
        marginTop: "auto !important"
    },
    [`& .${PREFIX}-modalRoot`]: {
        ...modalStyle(theme).modalRoot
    },
    [`& .${PREFIX}-modalBody`]: {
        ...modalStyle(theme).modalBody
    },
    [`& .${PREFIX}-justifyContentCenter`]: {
        justifyContent: "space-between"
    }
});

let classes = {
    modalRoot: `${PREFIX}-modalRoot`,
    modal: `${PREFIX}-modal`,
    modalBody: `${PREFIX}-modalBody`,
    justifyContentCenter: `${PREFIX}-justifyContentCenter`,
};
export { manageSessionStyle, classes };

export default manageSessionStyle;
