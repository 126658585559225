import React from 'react';
import ReactDOM from 'react-dom/client';
import "./app/assets/scss/material-kit-pro-react.scss?v=1.7.0";
import App from './app/views/App';
import reportWebVitals from './reportWebVitals';
import configureStore from './store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import settings from '@salesforce/design-system-react/components/settings';


let store = configureStore();
const root = ReactDOM.createRoot(document.getElementById('appRoot'));
settings.setAppElement('#appRoot');
root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: function(registration, serviceWorker){
        registration.update();
        serviceWorker.postMessage('SKIP_WAITING');
    },
    onSuccess: function(registration, serviceWorker){
        //console.log("Service Worker Installed");
    }
});
