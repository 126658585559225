
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";

const PREFIX = 'InactiveUserModal';
const inactiveUserModalStyle = theme => ({
    ...modalStyle(theme),
    [`& .${PREFIX}-modalRoot`]: {
        ...modalStyle(theme).modalRoot,
    },
    [`& .${PREFIX}-modal`]: {
        ...modalStyle(theme).modal,
        [theme.breakpoints.up("sm")]: {
            maxWidth: "500px",
        },
        marginTop: "20px !important"
    },
    [`& .${PREFIX}-modalBody`]: {
        ...modalStyle(theme).modalBody,
    },
    [`& .${PREFIX}-modalFooter`]: {
        ...modalStyle(theme).modalFooter,
    },
    [`& .${PREFIX}-justifyContentCenter`]: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important"
    },
    [`& .${PREFIX}-modalTitle`]:{
        textAlign:"center"
    },
    [`& .${PREFIX}-modalCloseButton`]: {
        ...modalStyle(theme).modalCloseButton,
        margin: "-24px -24px 0px 0px"
    },
    [`& .${PREFIX}-justifyContentRight`]: {
        justifyContent: "center"
    }
});

let classes = {
    modal: `${PREFIX}-modal`,
    modalRoot: `${PREFIX}-modalRoot`,
    modalBody: `${PREFIX}-modalBody`,
    modalFooter: `${PREFIX}-modalFooter`,
    justifyContentCenter: `${PREFIX}-justifyContentCenter`,
    modalTitle: `${PREFIX}-modalTitle`,
    modalCloseButton: `${PREFIX}-modalCloseButton`,
    justifyContentRight: `${PREFIX}-justifyContentRight`
};
export { inactiveUserModalStyle, classes };

export default inactiveUserModalStyle;
