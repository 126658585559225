const PasswordMatch = {
    check(password,callBack){
		const passwordLength = 8;
		let requireLowerletter = true;
		let requireUpperletter = true;
		let requireNumber = true;
		let requireSymbol = false;
        let requireLength = false;

        //Lowercase test
        if (/[a-z]/.test(password)) {        
            requireLowerletter = true;
        }

        //Uppercase test
        if (/[A-Z]/.test(password)) {        
            requireUpperletter = true;
        }
        
        //Special character test
        const regex = new RegExp(/[-!$%^&*()_+|~=`[{/}\]:;<>?,.@#'"]/);
        if (regex.test(password) || password.indexOf('\\') >= 0) {
            requireSymbol = true;
        }

        //Number test
        if (/[0-9]/.test(password)) {
            requireNumber = true;
        }

        //Length test
        if (password.length >= passwordLength) {
            requireLength = true;
        }
        callBack(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength);
        if (requireLowerletter && requireUpperletter && requireNumber && requireSymbol && requireLength) {
            return true;
        } else {
            return false;
        }
    }
}

export default PasswordMatch;