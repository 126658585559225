import React, { useEffect } from "react";
import { connect } from "react-redux";
import Config from "../../../Config";
import Api from "../../assets/js/utils/Api";
import { createBrowserHistory } from "history";
import querystring from "querystring";
import { helper } from "../../assets/js/utils/Element";
import axios from "axios";
// import { Redirect } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { mapStateToProps } from "../../store/reducers/rootReducer";
import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
import Button from "../components/CustomButtons/Button";
import CustomInput from "../components/CustomInput/CustomInput.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import GridContainer from "../components/Grid/GridContainer.jsx";
import Link from "@mui/material/Link";
import Loader from '../components/Loader';
import ConfirmationModal from "../components/Modals/ConfirmationModal";
import ManageSession from "../components/ManageSession";
// import Config from "../../../Config";

import { styled } from '@mui/material/styles';
import { usersPageStyle, classes } from "../../assets/jss/usersPageStyle.jsx";
const UsersRoot = styled('div')(({ theme }) => usersPageStyle(theme));
const history = createBrowserHistory();
LicenseInfo.setLicenseKey(
    Config.getMaterialUiGridLicenseKey(),
);
const Users = (props) => {
    const { authorized, user } = props.state;
    const params = helper.getParams();
    const [state, setState] = React.useState({
        email: "",
        row: null,
        page: 1,
        callApi: true,
        loading: false,
        loadingMore: false,
        response: null,
        viewMore: false,
        RF_LIMIT: 10,
        RF_OFFSET: 0,
        showLoadMore: false,
        cancelToken: null,
        RF_ORDER_BY: null,
        confirmationModal: false,
        onfirmationMessage: false,
        errorMessage: null,
        saving: false,
        ...params
    });
    useEffect(() => {
        // if(!authorized){
        //     window.location.href = Config.getLoginUrl("/login");
        //     return;
        // }
        if(state.callApi && authorized && (user.BusinessAdmin__c || user.SuperAdmin__c)){
            loadData();
        }
        
        return function cleanup() {
            if (state.cancelToken) {
                state.cancelToken.cancel('Request Cancelled');
            }
        };
    });
    function loadData(searchMode = false, clearSearch = false){
        const source = axios.CancelToken.source();
        const { email, RF_OFFSET, RF_ORDER_BY } = state;
        const requestData = {
            RF_OFFSET: (state.response && searchMode === false ? state.response.length : RF_OFFSET),
            RF_LIMIT: state.RF_LIMIT,
        };
        if(searchMode){
            requestData['RF_OFFSET'] = 0;
        }
        if(!clearSearch){
            if(email.length > 0){
                requestData['AuthorizationEmail__c'] = email;
            }
            if(RF_ORDER_BY !== null){
                requestData['RF_ORDER_BY'] = RF_ORDER_BY;
            }
        }
        history.push("/users?"+querystring.stringify(requestData));
        requestData['sobjecttypename'] = 'EAO_ReactforceUser__c';

        let newState = {
            ...state,
            loading: (!state.viewMore), 
            loadingMore: state.viewMore,
            cancelToken: source,
            callApi: false,
            RF_OFFSET: requestData['RF_OFFSET']
        };
        if(clearSearch){
            newState = Object.assign(newState, {
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                stage: "",
                disposition: "",
                timezone: "",
                assignedEmail: "",
            });
        }
        
        Api.getObjects(requestData, source).then(data => {
            let oldResponse = (state.viewMore ? state.response:[]);
            if(searchMode){
                oldResponse = [];
            }
            const response = oldResponse.concat(data);
            setState({
                ...newState,
                callApi: false,
                response: response,
                loading: false,
                loadingMore: false,
                viewMore: false,
                showLoadMore: (data.length < parseInt(state.RF_LIMIT) ? false : true),
            });
        }).catch(err => {
            console.log(err);
        });

        setState(newState);
    }
    function handleChange(e, name) {
        let value = e.target.value;
        setState({
            ...state,
            [name]: value,
        });
    }

    function onViewMore(){
        setState({
            ...state,
            callApi: true,
            viewMore: true,
        });
    }
    function onUpdateAttribute(e, params, attribute, status, message){
        e.preventDefault();
        let user = Object.assign({}, params.row.user);
        user[attribute] = status;
        setState({
            ...state,
            row: user,
            confirmationModal: true,
            errorMessage: null,
            confirmationMessage: message
        });
    }
    const columns = [
        { field: "email", headerName: "Email", width: 300 },
        {
            field: "isActive",
            headerName: "Is Active",
            width: 220,
            renderCell: (params) => {
                const activateMsg = "Are you sure you want to activate this user?";
                const deactivateMsg = "Are you sure you want to deactivate this user?";
                return (
                    params.row.user.IsActive__c ?
                        <Link onClick={(e) => onUpdateAttribute(e, params, 'IsActive__c', false, deactivateMsg)} href="/">Deactivate</Link>
                    :
                    <Link onClick={(e) => onUpdateAttribute(e, params, 'IsActive__c', true, activateMsg)} href="/">Activate</Link>
                )
            }
        },
        {
            field: "businessAdmin",
            headerName: "Business Admin",
            width: 220,
            renderCell: (params) => {
                const activateMsg = "Are you sure you want to activate business admin?";
                const deactivateMsg = "Are you sure you want to deactivate business admin?";
                return (
                    params.row.user.BusinessAdmin__c ?
                        <Link onClick={(e) => onUpdateAttribute(e, params, 'BusinessAdmin__c', false, deactivateMsg)} href="/">Deactivate</Link>
                    :
                    <Link onClick={(e) => onUpdateAttribute(e, params, 'BusinessAdmin__c', true, activateMsg)} href="/">Activate</Link>
                )
            }
        },
        {
            field: "beta",
            headerName: "Beta",
            width: 220,
            renderCell: (params) => {
                const activateMsg = "Are you sure you want to activate beta?";
                const deactivateMsg = "Are you sure you want to deactivate beta?";
                return (
                    params.row.user.Beta__c ?
                        <Link onClick={(e) => onUpdateAttribute(e, params, 'Beta__c', false, deactivateMsg)} href="/">Deactivate</Link>
                    :
                    <Link onClick={(e) => onUpdateAttribute(e, params, 'Beta__c', true, activateMsg)} href="/">Activate</Link>
                )
            }
        },
    ];
    function getTableData(){
        if(state.response === null){
            return [];
        }
        let data = [];
        state.response.map((user) => {
            data.push({
                id: user.Id,
                user: user,
                email: user.AuthorizationEmail__c,
                isActive: user.IsActive__c,
                businessAdmin: user.BusinessAdmin__c,
            });
            return null;
        });
        return data;
    }
    function handleSearchSubmit(e){
        e.preventDefault();
        loadData(true);
    }
    function onConfirmationClose(){
        setState({
            ...state,
            confirmationModal: false,
            confirmationMessage: null,
            errorMessage: null,
            saving: false
        });
    }
    function onConfirmationSuccess(){
        let response = state.response;
        let newResponse = [];
        const oldRow = state.row;
        response.map((row, key) => {
            if(oldRow.Id === row.Id){
                newResponse.push(state.row);
            }else{
                newResponse.push(row);
            }
            return null;
        });
        const requestData = {
            "sObject": oldRow
        }
        const source = axios.CancelToken.source();
        Api.updateObject(requestData, source).then(data => {
            setState({
                ...state,
                response: newResponse,
                confirmationModal: false,
                confirmationMessage: null,
                saving: false
            });
        }).catch(err => {
            setState({
                ...state,
                saving: false,
                errorMessage: err.message
            });
        });
        setState({
            ...state,
            errorMessage: null,
            saving: true
        });
    }
    if (!authorized || (!user.BusinessAdmin__c && !user.SuperAdmin__c)) {
        return <></>
    }
    return (
        <UsersRoot className={classes.home}>
            <form method="post" onSubmit={(e) => handleSearchSubmit(e)} noValidate>
                <GridContainer alignItems="center" className={classes.searchForm}>
                    <GridItem md={3}>
                        <CustomInput
                            id="input-email"
                            labelText="Email"
                            inputProps={{
                                onChange: (e) => handleChange(e, "email"),
                                name: "email",
                                type: "email",
                                value: state.email,
                            }}
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    <GridItem md={3} className={classes.searchFormButtons}>
                        <Button color="primary" type="submit">Search</Button>
                    </GridItem>
                </GridContainer>
            </form>
            <DataGridPro
                rows={getTableData()}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[10]}
                paginationMode="server"
                checkboxSelection
                autoHeight
                disableColumnMenu
                disableColumnReorder
                disableSelectionOnClick
                loading={state.loading}
                pagination={false}
                hideFooter
            />
            {
                state.confirmationModal ?
                    <ConfirmationModal
                        open={state.confirmationModal}
                        message={state.confirmationMessage}
                        saving={state.saving}
                        errorMessage={state.errorMessage}
                        showCloseIcon={(state.errorMessage !== null)}
                        onClose={() => onConfirmationClose()}
                        onSuccess={() => onConfirmationSuccess()}
                    />
                :
                null
            }
            {
                state.loadingMore ?
                    <Loader color="primary" align="center" />
                :
                    state.showLoadMore ?
                        <Button color="primary" onClick={onViewMore}>Load More</Button>
                    : null
            }
            <ManageSession />
        </UsersRoot>
    );
};

Users.propTypes = {
    classes: PropTypes.object,
};

export default connect(mapStateToProps)(Users);
