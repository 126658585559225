import { dangerColor, whiteColor } from "./material-kit-pro-react";
const PREFIX = 'Users';
const usersPageStyle = theme => ({
    [`&.${PREFIX}-home`]: {
        textAlign: "center",
        "& .MuiDataGrid-root": {
            fontSize: "0.75rem"
        }
    },
    [`& .${PREFIX}-searchForm`]: {
        marginBottom: "15px"
    },
    [`& .${PREFIX}-searchFormButtons`]: {
        display: "inline-flex",
        flexBasis: "fit-content",
        maxWidth: "none",
        marginTop: "8px",
        "& button + button": {
            marginLeft: "10px"
        }
    },
    [`& .${PREFIX}-duplicate`]: {
        display: "inline-flex",
        background: dangerColor[0],
        color: whiteColor,
        padding: "5px",
        borderRadius: "4px",
        marginRight: "5px",
        fontSize: "10px",
        position: "relative",
        transition: "all 0.3s ease",
        "&:hover": {
            paddingRight: "30px",
            "& $removeDuplicate": {
                display: "block"
            }
        }
    },
    [`& .${PREFIX}-removeDuplicate`]: {
        cursor: "pointer",
        padding: "5px",
        position: "absolute",
        boxShadow: "none",
        height: "auto",
        width: "auto",
        minWidth: "24px",
        fontSize: "13px",
        top: "0px",
        right: "0px",
        margin: "auto",
        lineHeight: "15px",
        display: "none"
    }
});

let classes = {
    home: `${PREFIX}-home`,
    searchForm: `${PREFIX}-searchForm`,
    searchFormButtons: `${PREFIX}-searchFormButtons`,
    duplicate: `${PREFIX}-duplicate`,
    removeDuplicate: `${PREFIX}-removeDuplicate`
};
export { usersPageStyle, classes };

export default usersPageStyle;
