import React from 'react';
import Config from "../../../Config";
import { styled } from '@mui/material/styles';
import { splashScreenStyle, classes } from "../../assets/jss/splashScreenStyle.jsx";
const SplashScreenRoot = styled('div')(({ theme }) => splashScreenStyle(theme));

const SplashScreen = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
    }  
    render() {
        const cdn = Config.getCdnUrl();
        const logoUrl = cdn+"/assets/img/logo.png";
        return (
            <SplashScreenRoot className={classes.overlay}>
                <img src={logoUrl}  alt="Loading" className={classes.loader}/>
            </SplashScreenRoot>
        )
    }
}

export default SplashScreen;
