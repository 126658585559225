/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
// core components
import { styled } from '@mui/material/styles';
import { typographyStyle, classes } from "../../../assets/jss/material-kit-pro-react/components/typographyStyle.jsx";
const WarningRoot = styled('div')(({ theme }) => typographyStyle(theme));

function Warning({ ...props }) {
  const { children } = props;
  return (
    <WarningRoot className={classes.defaultFontStyle + " " + classes.warningText}>
      {children}
    </WarningRoot>
  );
}

Warning.propTypes = {
  children: PropTypes.node
};

export default Warning;
