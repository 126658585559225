import React from "react";
import Config from "../../../Config";
import { Navigate } from "react-router";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material/core components
import { connect } from "react-redux";
import { mapStateToProps } from "../../store/reducers/rootReducer";
import { helper } from "../../assets/js/utils/Element";
import Button from "../components/CustomButtons/Button";
import SFConnectModal from "../components/Modals/SFConnectModal";

import { styled } from '@mui/material/styles';
import { sfAuthPageStyle, classes } from "../../assets/jss/sfAuthPageStyle.jsx";
const SFAuthRoot = styled('div')(({ theme }) => sfAuthPageStyle(theme));

const SFAuth = (props) => {
    const [state, setState] = React.useState({
        connectModal: false
    });
    const { authorized } = props.state;
    const code = helper.getParam("code");
    if(code && code !== "null" && code.length > 0){
        localStorage.setItem("sf_auth_code", code);
        if(authorized){
            return <Navigate to={"/salesforce/authorization/link?code="+code} />
        }
    }
    function onConnectModal(status = false){
        setState({
            connectModal: status
        });
    }
    function onConnectModalSuccess(data){
        let url = data.sfUrl;
        url = url.endsWith('/') ? url.slice(0, -1) : url;
        url += "/";
        let clientId = null;
        if(data.clientId && data.clientId.length > 0){
            clientId = data.clientId;
        }
        const authLink = Config.getSFLoginUrl(url, "/salesforce/authorization", clientId);
        window.location.href = authLink;
    }
    if(!authorized){
        return <Navigate to="/login" />
    }
    return (
        <SFAuthRoot className={classes.main}>
            <p>In order for Mogli to send and receive messages from your Salesforce org, an authorized user must grant a refresh token to Mogli.</p>
            <span>Connect Your Salesforce Org</span>
            <Button color="white" onClick={() => onConnectModal(true)}>
                Connect
            </Button>
            {
                state.connectModal ?
                    <SFConnectModal
                        open={state.connectModal}
                        onClose={() => onConnectModal()}
                        onSuccess={(data) => onConnectModalSuccess(data)}
                    />
                :
                null
            }
        </SFAuthRoot>
    )
};

SFAuth.propTypes = {
    classes: PropTypes.object,
};

export default connect(mapStateToProps)(SFAuth);
