import {
  grayColor,
  roseColor,
  primaryColor,
  infoColor,
  successColor,
  whiteColor,
} from "./material-kit-pro-react.jsx";

const PREFIX = 'Loader';
const loaderStyle = theme => ({
    [`&.${PREFIX}-primary`]: {
        color: primaryColor[0]
    },
    [`&.${PREFIX}-info`]: {
        color: infoColor[0]
    },
    [`&.${PREFIX}-gray`]: {
        color: grayColor[26]
    },
    [`&.${PREFIX}-success`]: {
        color: successColor[0],
    },
    [`&.${PREFIX}-rose`]: {
        color: roseColor[0],
    },
    [`&.${PREFIX}-customInfo`]: {
        color: infoColor[8]
    },
    [`&.${PREFIX}-customSuccess`]: {
        color: successColor[8],
    },
    [`&.${PREFIX}-customRose`]: {
        color: roseColor[5],
    },
    [`&.${PREFIX}-white`]: {
        color: whiteColor
    },
    [`&.${PREFIX}-saving`]: {
        height: "18px",
        width: "18px",
        "& .MuiCircularProgress-root": {
            height: "18px !important",
            width: "18px !important",
        }
    },
    [`&.${PREFIX}-custom`]: {
        color: theme.palette.custom.main,
    }
});

const classes = {
    'primary': `${PREFIX}-primary`,
    'info': `${PREFIX}-info`,
    'gray': `${PREFIX}-gray`,
    'success': `${PREFIX}-success`,
    'rose': `${PREFIX}-rose`,
    'customInfo': `${PREFIX}-customInfo`,
    'customSuccess': `${PREFIX}-customSuccess`,
    'customRose': `${PREFIX}-customRose`,
    'white': `${PREFIX}-white`,
    'saving': `${PREFIX}-saving`,
    'custom': `${PREFIX}-custom`
};

export { loaderStyle, classes };

export default loaderStyle;
