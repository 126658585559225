import React from 'react'
import { matchPath } from 'react-router';
import { Navigate } from 'react-router-dom'
import { connect } from "react-redux";
import AuthApi from "../../../assets/js/utils/Auth";
import Api from "../../../assets/js/utils/Api";
import LoaderComponent from '../../components/Loader'
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
// import InfoArea from "../../components/InfoArea/InfoArea.jsx";
import InputAdornment from "@mui/material/InputAdornment";
import Warning from "../../components/Typography/Warning.jsx";
import Snackbar from '@mui/material/Snackbar';
import { mapStateToProps } from "../../../store/reducers/rootReducer";
import InactiveUserModal from "../../components/Modals/InactiveUserModal";

// @mui/icons-material
// import Timeline from "@mui/icons-material/Timeline";
// import Code from "@mui/icons-material/Code";
// import Group from "@mui/icons-material/Group";
import Email from "@mui/icons-material/Email";
import AuthIcon from "@mui/icons-material/Lock";
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import { styled } from '@mui/material/styles';
import { signupVerificationPageStyle, classes } from "../../../assets/jss/auth/signupVerificationPageStyle.jsx";
const SignupVerificationRoot = styled('div')(({ theme }) => signupVerificationPageStyle(theme));

const SignupVerification = class extends React.Component {
    constructor(props){
        super(props);

        this.history = this.props.history;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resendCode = this.resendCode.bind(this);
        this.confirmSignUp = this.confirmSignUp.bind(this);
        this.onResendSignUpSuccess = this.onResendSignUpSuccess.bind(this);
        this.onResendSignUpFail = this.onResendSignUpFail.bind(this);
        this.codeResendNotice = this.codeResendNotice.bind(this);

        const match = matchPath("/signup/verification/:email", window.location.pathname);
        let email = "";
        if(match !== null && match.params.hasOwnProperty("email")){
            email = match.params.email;
        }

        let code = "";
        if(match !== null && match.params.hasOwnProperty("code")){
            code = match.params.code;
        }

        this.state = {
            email: email,
            code: code,
            loading: false,
            validation: {
                email: '',
                code: '',
                isValid: false
            },
            requestCode: false,
            showError: false,
            errorMessage: "",
            codeResendNotice: false,
            inactiveUserModal: false
        };
    }
    componentDidMount(){
        const { email, code } = this.state;
        if(email.length > 0 && code.length > 0){
            const isValid = this.validateForm(true);
            if(!isValid || this.state.loading){
                return;
            }
            this.setState({
                loading: true, 
                showError: false, 
                requestCode: true,
                validation: {
                    ...this.state.validation,
                    email: "success",
                    isValid: true
                }
            });
            AuthApi.confirmSignUp(email, code, this);
        }else if(email.length > 0){
            this.setState({
                loading: true, 
                showError: false,
                validation: {
                    ...this.state.validation,
                    email: "success",
                    isValid: true
                }
            });
            AuthApi.resendSignUp(email,this);
        }
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        if(name === "email"){
            state[name] = e.target.value.toLowerCase();
        }
        this.setState(state);
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    componentDidUpdate(prevProps, prevState){
        const { email } = this.state;
        let validateEmail = false;
        if(prevState.email !== email){
            validateEmail = true;
        }
        if(validateEmail){
            this.validateForm(validateEmail);
        }
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm(true);
        if(!isValid || this.state.loading){
            return;
        }
        const { email } = this.state;
        AuthApi.resendSignUp(email,this);
        this.setState({loading: true, showError: false});
    }
    validateForm(validateEmail = false){
        let validation = {
            email: (validateEmail ? 'success': this.state.validation.email),
            isValid: true
        };
        var emailRegex = /\S+@\S+\.\S+/;
        if(validateEmail && (this.state.email.length <= 0 || !emailRegex.test(this.state.email))){
            if(validateEmail){
                validation.email = "error";
            }
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    onValidateSuccess(){
        this.setState({loading: false, requestCode: true});
    }
    onValidateConfirmSuccess(data){
        console.log(data);
        const { email, password } = this.state;
        const that = this;
        AuthApi.signIn(email, password, {
            onValidateSuccess: function(data){
                if(data.numberOfResponses > 0){
                    const user = Api.prepareMemberObject(data.response[0]);
                    if(user.hasOwnProperty("IsActive__c") && !user.IsActive__c){
                        AuthApi.logout();
                        that.setState({
                            inactiveUserModal: true
                        });
                        return null;
                    }
                    that.history.push("/"+window.location.search);
                    that.store.dispatch({
                        type: "LOGIN",
                        state: {
                            authorized: true,
                            user: user
                        }
                    });                    
                }
            },
            onValidateFail: function(err){
                let url = "/login"+window.location.search;
                // if(url.indexOf("?") === -1){
                //     url += "?email="+email;
                // }else{
                //     url += "&email="+email;
                // }
                that.history.push(url);
            }
        });
    }
    onValidateFail(err){
        let errorMessage = "";
        if(typeof(err) === "string"){
            errorMessage = err;
        }else{
            errorMessage = err.message;
        }
        this.setState({
            loading: false,
            showError: true,
            errorMessage: errorMessage
        });
    }
    resendCode(event){
        event.preventDefault();
        event.stopPropagation();
        this.setState({codeResendNotice: true});
        AuthApi.resendSignUp(this.state.email,this);
    }
    confirmSignUp(event){
        event.preventDefault();
        event.stopPropagation();
        if (this.state.code.length <= 0) {
            this.setState({
                validation: {
                    ...this.state.validation,
                    code: "error"
                }
            });
            return;
        }
        const code = this.state.code;
        this.setState({loading: true, showError: false});
        AuthApi.confirmSignUp(this.state.email,code,this);
    }
    onResendSignUpSuccess(){
        this.setState({loading: false, requestCode: true});
    }
    onResendSignUpFail(e){
        this.onValidateFail(e);
    }
    onEmailClear(){
        this.setState({
            email: "",
            errorMessage: "",
            showError: false
        });
    }
    codeResendNotice(open){
        this.setState({codeResendNotice: open});
    }
    onInactiveUserModalClose(){
        AuthApi.logout();
        this.props.dispatch({type: 'LOGOUT'});
        this.history.push("/");
    }
    render() {
        const { validation, loading, requestCode, showError, errorMessage, inactiveUserModal } = this.state;
        const { authorized } = this.props.state;
        if(authorized && !inactiveUserModal){
            return <Navigate to={'/'+window.location.search} />          
        }
        return (
            <SignupVerificationRoot
                className={classes.pageHeader}
            >
                <div className={classes.container}>
                    <GridContainer justifyContent="center">
                    <GridItem xs={12} md={5}>
                        <Card className={classes.cardSignup}>
                        <h5 className={classes.cardTitle}>Verify Your Account</h5>
                        <CardBody>
                            <GridContainer justifyContent="center">
                                {/* <GridItem xs={12} sm={5} md={5}>
                                    <InfoArea
                                    className={classes.infoArea}
                                    title="Marketing"
                                    description="We've created the marketing campaign of the website. It was a very interesting collaboration."
                                    icon={Timeline}
                                    iconColor="rose"
                                    />
                                    <InfoArea
                                    className={classes.infoArea}
                                    title="Fully Coded in HTML5"
                                    description="We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub."
                                    icon={Code}
                                    iconColor="primary"
                                    />
                                    <InfoArea
                                    className={classes.infoArea}
                                    title="Built Audience"
                                    description="There is also a Fully Customizable CMS Admin Dashboard for this product."
                                    icon={Group}
                                    iconColor="info"
                                    />
                                </GridItem> */}
                                <GridItem xs={12} >
                                    {/* <div className={classes.textCenter}>
                                    <Button justIcon round color="twitter">
                                        <i
                                        className={classes.socials + " fab fa-twitter"}
                                        />
                                    </Button>
                                    {` `}
                                    <Button justIcon round color="dribbble">
                                        <i
                                        className={classes.socials + " fab fa-dribbble"}
                                        />
                                    </Button>
                                    {` `}
                                    <Button justIcon round color="facebook">
                                        <i
                                        className={classes.socials + " fab fa-facebook-f"}
                                        />
                                    </Button>
                                    {` `}
                                    <h4 className={classes.socialTitle}>
                                        or be classical
                                    </h4>
                                    </div> */}
                                    {
                                        requestCode ?
                                            <div>
                                                <GridItem>
                                                    <Warning>
                                                        We just sent you a six digit verification code to your email address.  Enter it below to verify your account.
                                                    </Warning>
                                                </GridItem>
                                                <GridContainer>
                                                    <GridItem>
                                                        {
                                                            showError ?
                                                            <GridItem className={"passwordCheck-notValid-customizable "+classes.verifyError}>
                                                                <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                                                                <span className="checkPasswordText-lowerletter">{errorMessage}</span>
                                                            </GridItem>
                                                            :
                                                            <></>
                                                        }
                                                        <form onSubmit={this.confirmSignUp} noValidate>
                                                            <GridItem>                             
                                                                <CustomInput
                                                                    success={validation.code === "success"}
                                                                    error={validation.code === "error"}
                                                                    id="input-code"
                                                                    inputProps={{
                                                                        required: true,
                                                                        onChange: (e) => this.handleChange(e,'code'),
                                                                        value: this.state.code,
                                                                        name: "code",
                                                                        type: "text",
                                                                        startAdornment: (
                                                                            <InputAdornment position="start" className={classes.inputAdornment} >
                                                                                <VerifiedUser className={classes.inputAdornmentIcon}/>
                                                                            </InputAdornment>
                                                                        ),
                                                                        placeholder:"Verification Code"
                                                                    }}                                    
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                />
                                                            </GridItem>                              
                                                            <GridItem className={classes.textCenter}>
                                                                {
                                                                    loading ?
                                                                        <LoaderComponent />
                                                                    :
                                                                        <>
                                                                        <Button type="submit" color="primary" round>
                                                                            <AuthIcon className={classes.icons} /> Confirm Account
                                                                        </Button>
                                                                        <p className="redirect-customizable text-center mt-2"><span>Didn't receive a code?</span>&nbsp;<span className={classes.anchor} onClick={(e) => this.resendCode(e)}>Resend it</span></p>
                                                                        </>
                                                                }
                                                                
                                                            </GridItem>
                                                        </form>
                                                    </GridItem>
                                                </GridContainer>                        
                                            </div>
                                        :
                                        <>
                                            {
                                                showError ?
                                                <GridItem className={"passwordCheck-notValid-customizable"}>
                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                                                    <span className="checkPasswordText-lowerletter">{errorMessage}</span>
                                                </GridItem>
                                                :
                                                <></>
                                            }
                                            <form className={classes.form} onSubmit={this.handleSubmit}>
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses
                                                    }}
                                                    success={validation.email === "success"}
                                                    error={validation.email === "error"}
                                                    id="input-email"
                                                    onClear={() => this.onEmailClear()}
                                                    inputProps={{
                                                        onChange: (e) => this.handleChange(e, 'email'),
                                                        value: this.state.email,
                                                        name: "email",
                                                        startAdornment: (
                                                            <InputAdornment position="start" className={classes.inputAdornment} >
                                                                <Email className={classes.inputAdornmentIcon}/>
                                                            </InputAdornment>
                                                        ),
                                                        placeholder:"Email"
                                                    }}
                                                />
                                                <div className={classes.textCenter}>
                                                    {
                                                        loading ?
                                                            <LoaderComponent />
                                                        :
                                                        validation.isValid ?
                                                            <Button round color="primary" onClick={this.handleSubmit}>Verify Account</Button>
                                                        :
                                                            <Button round color="primary" disabled>Verify Account</Button>
                                                    }                                        
                                                </div>
                                            </form>
                                        </>
                                    }
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        </Card>
                    </GridItem>
                    </GridContainer>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className={classes.snackbar}
                    open={this.state.codeResendNotice}
                    autoHideDuration={1000}
                    onClose={this.codeResendNotice}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Verification Code Resent!</span>}
                />
                {
                    inactiveUserModal ?
                        <InactiveUserModal
                            open={inactiveUserModal}
                            onClose={() => this.onInactiveUserModalClose()}
                        />
                    :
                    null
                }
            </SignupVerificationRoot>
        )
    }
}

export default connect(mapStateToProps)(SignupVerification);