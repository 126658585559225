/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  containerFluid,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor
} from "../../material-dashboard-pro-react.jsx";

const PREFIX = 'AdminNavbar';
const adminNavbarStyle = () => ({
  [`& .${PREFIX}-appBar`]: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    paddingTop: "10px",
    zIndex: "1029",
    color: grayColor[6],
    border: "0",
    borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block"
  },
  [`& .${PREFIX}-container`]: {
    ...containerFluid,
    minHeight: "50px"
  },
  [`& .${PREFIX}-flex`]: {
    flex: 1
  },
  [`& .${PREFIX}-title`]: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    margin: "0 !important",
    letterSpacing: "unset",
    "&:hover,&:focus": {
      background: "transparent"
    }
  },
  [`& .${PREFIX}-primary`]: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  [`& .${PREFIX}-info`]: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  [`& .${PREFIX}-success`]: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  [`& .${PREFIX}-warning`]: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  [`& .${PREFIX}-danger`]: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  [`& .${PREFIX}-sidebarMinimize`]: {
    float: "left",
    padding: "0 0 0 15px",
    display: "block",
    color: grayColor[6]
  },
  [`& .${PREFIX}-sidebarMinimizeRTL`]: {
    padding: "0 15px 0 0 !important"
  },
  [`& .${PREFIX}-sidebarMiniIcon`]: {
    width: "20px",
    height: "17px"
  }
});

const classes = {
    appBar: `${PREFIX}-appBar`,
    container: `${PREFIX}-container`,
    flex: `${PREFIX}-flex`,
    title: `${PREFIX}-title`,
    primary: `${PREFIX}-primary`,
    info: `${PREFIX}-info`,
    success: `${PREFIX}-success`,
    warning: `${PREFIX}-warning`,
    danger: `${PREFIX}-danger`,
    sidebarMinimize: `${PREFIX}-sidebarMinimize`,
    sidebarMinimizeRTL: `${PREFIX}-sidebarMinimizeRTL`,
    sidebarMiniIcon: `${PREFIX}-sidebarMiniIcon`
};

export { adminNavbarStyle, classes };

export default adminNavbarStyle;
