/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  primaryColor,
  dangerColor,
  successColor,
  whiteColor,
  grayColor,
  defaultFont,
  roseColor,
  infoColor,
} from "../../material-kit-pro-react.jsx";

import { customSelectStyleDefault, classes as selectClasses } from "../../material-kit-pro-react/customSelectStyle.jsx";
const PREFIX = 'SFConnectModal';
const customSelectStyle = theme => ({
    ...customSelectStyleDefault(PREFIX),
    [`& .${PREFIX}-select`]: {
        ...customSelectStyleDefault.select,
        textAlign: "left",
        padding: "10px 0px 10px 10px"
    },
    [`& .${PREFIX}-disabled`]: {
        "&:before": {
            backgroundColor: "transparent !important"
        }
    },
    [`& .${PREFIX}-underline`]: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: primaryColor[0]
        }
    },
    [`& .${PREFIX}-underlineError`]: {
        "&:after": {
            borderBottomColor: dangerColor[0]
        }
    },
    [`& .${PREFIX}-underlineSuccess`]: {
        "&:after": {
            borderBottomColor: successColor[0]
        }
    },
    [`& .${PREFIX}-labelRoot`]: {
        ...defaultFont,
        color: grayColor[10] + " !important",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.42857",
        top: "12px",
        letterSpacing: "unset",
        zIndex: "1",
        cursor: "text",
        paddingLeft: "10px",
        "& + $underline": {
            marginTop: "0px"
        },
        "& .info_tooltip_icon": {
            marginLeft: "5px"
        },
        "& .private_icon": {
            cursor: "auto",
        },
        "&.MuiInputLabel-shrink": {
            paddingLeft: "0px",
            transform: "translate(0, -18px) scale(0.9)",
            "& .info_tooltip_icon": {
                width: "22.7px",
                height: "22.7px",
            }
        },
        width: "max-content"
    },
    [`& .${PREFIX}-labelRootError`]: {
        color: dangerColor[0] + " !important"
    },
    [`& .${PREFIX}-labelRootSuccess`]: {
        color: successColor[0] + " !important"
    },
    [`& .${PREFIX}-feedback`]: {
        position: "absolute",
        bottom: "9px",
        right: "25px",
        zIndex: "2",
        display: "block",
        width: "24px",
        height: "24px",
        textAlign: "center",
        pointerEvents: "none"
    },
    [`&.${PREFIX}-formControl`]: {
        margin: "0 0 17px 0",
        paddingTop: "17px",
        position: "relative",
        "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: grayColor[19]
        },
        "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
            color: grayColor[13]
        }
    },
    [`& .${PREFIX}-whiteUnderline`]: {
        "&:hover:not($disabled):before,&:before": {
        borderBottomColor: whiteColor
        },
        "&:after": {
        borderBottomColor: whiteColor
        }
    },
    [`& .${PREFIX}-input`]: {
        color: grayColor[13],
        height: "unset",
        "&,&::placeholder": {
        fontSize: "14px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: "400",
        lineHeight: "1.42857",
        opacity: "1"
        },
        "&::placeholder": {
        color: grayColor[12]
        }
    },
    [`& .${PREFIX}-whiteInput`]: {
        "&,&::placeholder": {
        color: whiteColor,
        opacity: "1"
        }
    },
    [`& .${PREFIX}-animate`]: {
        animation: "customSpin 1.3s infinite linear"
    },
    [`& .${PREFIX}-cancelIcon`]: {
        cursor: "pointer",
        pointerEvents: "all",
        right: "0px"
    },
    [`& .${PREFIX}-hasClearIcon`]: {
        right: "25px"
    },
    [`& .${PREFIX}-hasEndAdornment`]: {
        right: "50px"
    },
    [`& .${PREFIX}-hasClearEndAdornment`]: {
        right: "25px"
    },
    [`&.${PREFIX}-primary`]: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: primaryColor[0]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: primaryColor[0],
            borderLeftColor: primaryColor[0],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: primaryColor[0] + "!important"
            }
        }
    },
    [`&.${PREFIX}-rose`]: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: roseColor[0]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: roseColor[0],
            borderLeftColor: roseColor[0],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: roseColor[0] + "!important"
            }
        }
    },
    [`&.${PREFIX}-success`]: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: successColor[0]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: successColor[0],
            borderLeftColor: successColor[0],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: successColor[0] + "!important"
            }
        }
    },
    [`&.${PREFIX}-info`]: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: infoColor[0]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: infoColor[0],
            borderLeftColor: infoColor[0],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: infoColor[0] + "!important"
            }
        }
    },
    [`&.${PREFIX}-gray`]: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: grayColor[0]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: grayColor[0],
            borderLeftColor: grayColor[0],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: grayColor[0] + "!important"
            }
        }
    },
    [`&.${PREFIX}-customRose`]: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: roseColor[5]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: roseColor[5],
            borderLeftColor: roseColor[5],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: roseColor[5] + "!important"
            }
        }
    },
    [`&.${PREFIX}-customSuccess`]: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: successColor[8]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: successColor[8],
            borderLeftColor: successColor[8],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: successColor[8] + "!important"
            }
        }
    },
    [`&.${PREFIX}-customInfo`]: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: infoColor[8]+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: infoColor[8],
            borderLeftColor: infoColor[8],
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: infoColor[8] + "!important"
            }
        }
    },
    [`&.${PREFIX}-formControlDisabled`]: {
        "& > div": {
            "&:before": {
                borderBottomStyle: "dotted"
            },
        },
        "& .MuiSelect-icon": {
            display: "none"
        }
    },
    [`&.${PREFIX}-selectFormControl`]: {
        ...customSelectStyleDefault.selectFormControl,
        margin: "0px 0px 17px 0px !important",
        paddingTop: "17px",
        "& label + .MuiInput-formControl": {
            zIndex: "1",
            marginTop: "0px"
        },
        "& > div": {
            "&:before": {
                border: "1px solid "+grayColor[10] + " !important",
                padding: "10px",
            },
            "&:after": {
                borderBottomColor: primaryColor[0] + "!important"
            }
        }
    },
    [`&.${PREFIX}-custom`]: {
        "&.MuiListItem-root:hover, &.Mui-selected:hover": {
            backgroundColor: theme.palette.custom.main+" !important"
        },
        "&.MuiListItem-root:after, &.MuiListItem-root:hover:after": {
            borderBottomColor: theme.palette.custom.main,
            borderLeftColor: theme.palette.custom.main,
        },
        "&.MuiListItem-root.Mui-selected:hover:after": {
            borderBottomColor: whiteColor,
            borderLeftColor: whiteColor,
        },
        "&.MuiFormControl-root > div": {
            "&:after": {
                borderBottomColor: theme.palette.custom.main + "!important"
            }
        }
    },
});

let classes = {
    ...selectClasses(PREFIX),
    select: `${PREFIX}-select`,
    disabled: `${PREFIX}-disabled`,
    underline: `${PREFIX}-underline`,
    underlineError: `${PREFIX}-underlineError`,
    underlineSuccess: `${PREFIX}-underlineSuccess`,
    labelRoot: `${PREFIX}-labelRoot`,
    labelRootError: `${PREFIX}-labelRootError`,
    labelRootSuccess: `${PREFIX}-labelRootSuccess`,
    feedback: `${PREFIX}-feedback`,
    formControl: `${PREFIX}-formControl`,
    whiteUnderline: `${PREFIX}-whiteUnderline`,
    input: `${PREFIX}-input`,
    whiteInput: `${PREFIX}-whiteInput`,
    animate: `${PREFIX}-animate`,
    cancelIcon: `${PREFIX}-cancelIcon`,
    hasClearIcon: `${PREFIX}-hasClearIcon`,
    hasEndAdornment: `${PREFIX}-hasEndAdornment`,
    hasClearEndAdornment: `${PREFIX}-hasClearEndAdornment`,
    primary: `${PREFIX}-primary`,
    rose: `${PREFIX}-rose`,
    success: `${PREFIX}-success`,
    info: `${PREFIX}-info`,
    gray: `${PREFIX}-gray`,
    customRose: `${PREFIX}-customRose`,
    customSuccess: `${PREFIX}-customSuccess`,
    customInfo: `${PREFIX}-customInfo`,
    formControlDisabled: `${PREFIX}-formControlDisabled`,
    selectFormControl: `${PREFIX}-selectFormControl`,
    custom: `${PREFIX}-custom`
};
export { customSelectStyle, classes };
export default customSelectStyle;
