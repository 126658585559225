import React from "react";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/reducers/rootReducer";
import { styled } from '@mui/material/styles';
import { inactiveUserModalStyle, classes } from "../../../assets/jss/modals/inactiveUserModalStyle.jsx";
const InactiveUserModalRoot = styled(Dialog)(({ theme }) => inactiveUserModalStyle(theme));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const InactiveUserModal = (props) => {
    return (
        <InactiveUserModalRoot
            classes={{
                root: classes.modalRoot,
                paper: classes.modal,
            }}
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.onClose}
        >
            <DialogContent
                id="deleteWorkItem-slide-description"
                className={classes.modalBody}
            >
                Your account is not active… please contact your system admin
            </DialogContent>
            <DialogActions
                className={
                    classes.modalFooter + " " + classes.justifyContentRight
                }
            >
                <Button color="white" onClick={props.onClose}>
                    OK
                </Button>
            </DialogActions>
        </InactiveUserModalRoot>
    );
};

InactiveUserModal.defaultProps = {
    open: false,
};
InactiveUserModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    onSuccess: PropTypes.func
};
export default connect(mapStateToProps)(InactiveUserModal);
