/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @mui/material components
import Grid from "@mui/material/Grid";

function GridContainer({ ...props }) {
  const { children, className, ...rest } = props;
  return (
    <Grid container {...rest} className={className} style={{
        marginRight: "-15px",
        marginLeft: "-15px",
        width: "auto"
      }}>
      {children}
    </Grid>
  );
}

GridContainer.defaultProps = {
  className: ""
};

GridContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default GridContainer;
