import React from "react";
import Config from "../../../Config";
import { Navigate } from "react-router";
import axios from "axios";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material/core components
import { connect } from "react-redux";
import { mapStateToProps } from "../../store/reducers/rootReducer";
import Api from "../../assets/js/utils/Api";
import ConfirmationModal from "../components/Modals/ConfirmationModal";

const SFAuthLink = (props) => {
    const { authorized } = props.state;
    const code = localStorage.getItem("sf_auth_code");
    let confirmationModal = false;
    if(code && code.length > 0){
        confirmationModal = true;
    }
    const [state, setState] = React.useState({
        response: null,
        callApi: true,
        cancelToken: null,
        confirmationModal: confirmationModal,
        confirmationMessage: "Processing! Please wait.",
        saving: false,
        cancelButtonText: "Cancel",
        authTokenSuccess: false,
    });
    React.useEffect(() => {
        if(state.callApi && code && code.length > 0){
            const source = axios.CancelToken.source();
            let domain = Config.getSFAuthUrl().replaceAll("https://","").replaceAll("/", "");
            const requestData = {
                code: decodeURIComponent(code),
                client_id: Config.getSFClientId(),
                redirect_uri: Config.getRedirectUri("/salesforce/authorization"),
                domain: domain
            };
            Api.getSFAuthToken(requestData, source).then(data => {
                setState({
                    ...state,
                    callApi: false,
                    confirmationModal: true,
                    cancelButtonText: "Close",
                    showConfirmButton: false,
                    authTokenSuccess: true,
                    confirmationMessage: "You have successfully linked your salesforce.com user id to your Mogli account."
                });
                props.history.push("/salesforce/link/success");
            }).catch(err => {
                setState({
                    ...state,
                    callApi: false,
                    confirmationModal: true,
                    cancelButtonText: "Cancel",
                    showConfirmButton: true,
                    authTokenSuccess: false,
                    confirmationMessage: "You have not successfully linked your salesforce.com user id to your Mogli account. Try again!",
                    errorMessage: err.message
                });
                props.history.push("/salesforce/link/error");
            });
            setState({
                ...state,
                callApi: false,
                saving: true
            })
            return function cleanup() {
                if (state.cancelToken) {
                    state.cancelToken.cancel('Request Cancelled');
                }
            };
        }
    }, [state, code, props]);
    function onConfirmationModalClose(){
        setState({
            ...state,
            confirmationModal: false
        });
    }
    function onConfirmationModalSuccess(){
        window.location.href = Config.getSFLoginUrl(null, "/salesforce/authorization");
        setState({
            ...state,
            saving: (state.authTokenSuccess === false)
        });
    }
    if(!authorized){
        return <Navigate to="/login" />
    }
    if(!state.confirmationModal){
        return <Navigate to="/" />
    }
    return (
        <div>
            {
                state.confirmationModal ?
                    <ConfirmationModal
                        open={state.confirmationModal}
                        saving={state.saving}
                        showConfirmButton={state.showConfirmButton}
                        message={state.confirmationMessage}
                        cancelButtonText={state.cancelButtonText}
                        onClose={() => onConfirmationModalClose(false)}
                        onSuccess={() => onConfirmationModalSuccess()}
                    />
                :
                null
            }
        </div>
    )
};

SFAuthLink.propTypes = {
    classes: PropTypes.object,
};

export default connect(mapStateToProps)(SFAuthLink);
