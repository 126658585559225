import {
    successColor,
    dangerColor,
    grayColor
} from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
const PREFIX = 'SFConnectModal';
const sfConnectModalStyle = theme => ({
    ...modalStyle(theme),
    [`& .${PREFIX}-textCenter`]: {
        textAlign: "center"
    },
    [`& .${PREFIX}-modal`]: {
        ...modalStyle(theme).modal,
        marginTop: "auto !important"
    },
    [`& .${PREFIX}-modalRoot`]: {
        ...modalStyle(theme).modalRoot
    },
    [`& .${PREFIX}-modalHeader`]: {
        ...modalStyle(theme).modalHeader
    },
    [`& .${PREFIX}-modalBody`]: {
        ...modalStyle(theme).modalBody
    },
    [`& .${PREFIX}-modalCloseButton`]: {
        ...modalStyle(theme).modalCloseButton
    },
    [`& .${PREFIX}-modalClose`]: {
        ...modalStyle(theme).modalClose
    },
    [`& .${PREFIX}-modalBody`]: {
        ...modalStyle(theme).modalBody,
        paddingBottom: "0px",
        paddingTop: "0px",
        textAlign: "center",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > span": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        }
    },
    [`& .${PREFIX}-statusIcon`]: {
        textAlign: "center",
        "& svg": {
            width: "300px !important",
            height: "300px !important",
            color: successColor[0],
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
        }
    },
    [`& .${PREFIX}-errorIcon`]: {
        textAlign: "center",
        paddingBottom: theme.spacing(3),
        "& svg": {
            width: "200px !important",
            height: "200px !important",
            color: dangerColor[0],
            [theme.breakpoints.down("xs")]: {
                width: "130px !important",
                height: "130px !important",
            },
        }
    },
    [`& .${PREFIX}-contactBilling`]: {
        display: "block",
        textAlign: "right",
        marginTop: "30px",
        textDecoration: "underline"
    },
    [`& .${PREFIX}-modalCloseButtonLogin`]: {
        color: grayColor[1]+" !important",
        top: "16px !important",
        right: "16px !important",
        position: "absolute !important",
        zIndex: 1,
        "& svg": {
            marginRight: "0px"
        }
    },
    [`& .${PREFIX}-justifyContentCenter`]: {
        justifyContent: "center"
    },
    [`& .${PREFIX}-errorMessage`]: {
        color: dangerColor[0]
    }
});

let classes = {
    textCenter: `${PREFIX}-textCenter`,
    modalRoot: `${PREFIX}-modalRoot`,
    modal: `${PREFIX}-modal`,
    modalHeader: `${PREFIX}-modalHeader`,
    modalBody: `${PREFIX}-modalBody`,
    modalCloseButton: `${PREFIX}-modalCloseButton`,
    modalClose: `${PREFIX}-modalClose`,
    statusIcon: `${PREFIX}-statusIcon`,
    errorIcon: `${PREFIX}-errorIcon`,
    contactBilling: `${PREFIX}-contactBilling`,
    modalCloseButtonLogin: `${PREFIX}-modalCloseButtonLogin`,
    justifyContentCenter: `${PREFIX}-justifyContentCenter`,
    errorMessage: `${PREFIX}-errorMessage`
};
export { sfConnectModalStyle, classes };
  
export default sfConnectModalStyle;
  