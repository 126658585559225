import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material/core components

class Home extends React.Component {
    render() {
        return <div>Home Page</div>;
    }
}

Home.propTypes = {
    classes: PropTypes.object,
};

export default Home;
