/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";
import { mapStateToProps } from "../../../store/reducers/rootReducer";

// @mui/material components
import { styled } from '@mui/material/styles';
import { adminNavbarLinksStyle, classes } from "../../../assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";
const AdminNavbarLinksRoot = styled('div')(({ theme }) => adminNavbarLinksStyle(theme));

class AdminNavbarLinks extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.state = {
            openNotification: false,
            openProfile: false
        };
    }
    handleClickNotification = () => {
        this.setState({ openNotification: !this.state.openNotification });
    };
    handleCloseNotification = () => {
        this.setState({ openNotification: false });
    };
    handleClickProfile = () => {
        this.setState({ openProfile: !this.state.openProfile });
    };
    handleCloseProfile = () => {
        this.setState({ openProfile: false });
    };
    render() {
        const { rtlActive } = this.props;
        const { authorized } = this.props.state;
        if(!authorized){
            return null;
        }
        const wrapper = classNames({
            [classes.wrapperRTL]: rtlActive
        });
        const managerClasses = classNames({
            [classes.managerClasses]: true
        });
        return (
            <AdminNavbarLinksRoot className={wrapper}>
                <div className={managerClasses}>
                </div>
            </AdminNavbarLinksRoot>
        );
    }
}

AdminNavbarLinks.propTypes = {
    rtlActive: PropTypes.bool
};

export default connect(mapStateToProps)(AdminNavbarLinks);
