import { whiteColor } from "./material-dashboard-pro-react";

const PREFIX = 'SplashScreen';
const splashScreenStyle = theme => ({
    [`&.${PREFIX}-overlay`]: {
        position: "fixed",
        top:"0px",
        left:"0px",
        right:"0px",
        bottom:"0px",
        width: "100%",
        height: "100%",
        background: whiteColor,
        zIndex: "9999999"
    },
    [`& .${PREFIX}-loader`]:{
        maxHeight:"100px",
        maxWidth: "200px",
        position:"absolute", 
        top:"0px",
        left:"0px",
        right:"0px",
        bottom:"0px",
        margin:"auto"
    }
});

const classes = {
    'overlay': `${PREFIX}-overlay`,
    'loader': `${PREFIX}-loader`,
};

export { splashScreenStyle, classes };
  
export default splashScreenStyle;
  