/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
    primaryColor,
    dangerColor,
    successColor,
    whiteColor,
    grayColor,
    defaultFont,
    roseColor,
    infoColor,
  } from "../../material-kit-pro-react.jsx";
  
const PREFIX = 'CustomInput';
const customInputStyle = theme => ({
    [`& .${PREFIX}-disabled`]: {
        "&:before": {
            backgroundColor: "transparent !important"
        }
    },
    [`& .${PREFIX}-formControlDisabled`]: {
        "& .MuiInput-root:before": {
            backgroundColor: grayColor[19]
        }
    },
    [`&.${PREFIX}-formControlReadonly`]: {
        "& .MuiInput-root:before": {
            backgroundColor: grayColor[19]
        },
        "& .MuiInputLabel-root": {
            paddingLeft: "0px",
            transform: "translate(0, -8px) scale(0.9)",
        }
    },
    [`& .${PREFIX}-underline`]: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: primaryColor[0]
        }
    },
    [`& .${PREFIX}-underlineError`]: {
        "&:after": {
            borderBottomColor: dangerColor[0]
        }
    },
    [`& .${PREFIX}-underlineSuccess`]: {
        "&:after": {
            borderBottomColor: successColor[0]
        }
    },
    [`& .${PREFIX}-labelRoot`]: {
        ...defaultFont,
        color: grayColor[10] + " !important",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.42857",
        top: "12px",
        letterSpacing: "unset",
        zIndex: "1",
        cursor: "text",
        paddingLeft: "10px",
        left: "0px",
        position: "absolute",
        transform: "translate(0, 24px) scale(1)",
        "& + .MuiInput-underline": {
            marginTop: "0px"
        },
        "& .info_tooltip_icon": {
            marginLeft: "5px"
        },
        "& .private_icon": {
            cursor: "auto",
        },
        "&.MuiInputLabel-shrink": {
            paddingLeft: "0px",
            transform: "translate(0, -8px) scale(0.9)",
            "& .info_tooltip_icon": {
                width: "22.7px",
                height: "22.7px",
            }
        },
        width: "max-content"
    },
    [`& .${PREFIX}-labelRootError`]: {
        color: dangerColor[0] + " !important"
    },
    [`& .${PREFIX}-labelRootSuccess`]: {
        color: successColor[0] + " !important"
    },
    [`& .${PREFIX}-feedback`]: {
        position: "absolute",
        bottom: "9px",
        right: "2px",
        zIndex: "2",
        display: "block",
        width: "24px",
        height: "24px",
        textAlign: "center",
        pointerEvents: "none"
    },
    [`&.${PREFIX}-formControl`]: {
        margin: "0 0 17px 0",
        paddingTop: "27px",
        position: "relative",
        "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: grayColor[19]
        },
        "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
        color: grayColor[13]
        }
    },
    [`& .${PREFIX}-whiteUnderline`]: {
        "&:hover:not($disabled):before,&:before": {
        borderBottomColor: whiteColor
        },
        "&:after": {
        borderBottomColor: whiteColor
        }
    },
    [`& .${PREFIX}-input`]: {
        color: grayColor[13],
        height: "unset",
        "&,&::placeholder": {
            fontSize: "14px",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: "1.42857",
            opacity: "1",
            padding: "10px",
            zIndex: "1"
        },
        "&::placeholder": {
            color: grayColor[12],
            padding: "0px"
        },
        MozAppearance: "textfield",
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
            appearance: "none"
        }
    },
    [`& .${PREFIX}-whiteInput`]: {
        "&,&::placeholder": {
        color: whiteColor,
        opacity: "1"
        }
    },
    [`& .${PREFIX}-animate`]: {
        animation: "customSpin 1.3s infinite linear"
    },
    [`& .${PREFIX}-cancelIcon`]: {
        cursor: "pointer",
        pointerEvents: "all",
        right: "2px"
    },
    [`& .${PREFIX}-hasClearIcon`]: {
        right: "27px"
    },
    [`& .${PREFIX}-hasEndAdornment`]: {
        right: "52px"
    },
    [`& .${PREFIX}-hasClearEndAdornment`]: {
        right: "27px"
    },
    [`& .${PREFIX}-primary`]: {
        "&:hover:not($disabled):before": {
            border: "1px solid "+grayColor[10] + " !important",
            padding: "10px",
            height: "calc( 100% - 20px)"
        },
        "&:before": {
            border: "1px solid "+grayColor[10] + " !important",
            padding: "10px",
            height: "calc( 100% - 20px)",
            boxSizing: "unset"
        },
        "&:after": {
            borderBottomColor: primaryColor[0]
        },
        "&.Mui-focused": {
            color: primaryColor[0]
        }
    },
    [`& .${PREFIX}-rose`]: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: roseColor[0]
        },
        "&.Mui-focused": {
            color: roseColor[0]
        }
    },
    [`& .${PREFIX}-success`]: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: successColor[0]
        },
        "&.Mui-focused": {
            color: successColor[0]
        }
    },
    [`& .${PREFIX}-info`]: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: infoColor[0]
        },
        "&.Mui-focused": {
            color: infoColor[0]
        }
    },
    [`& .${PREFIX}-gray`]: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: grayColor[26]
        },
        "&.Mui-focused": {
            color: grayColor[26]
        }
    },
    [`& .${PREFIX}-customRose`]: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: roseColor[5]
        },
        "&.Mui-focused": {
            color: roseColor[5]
        }
    },
    [`& .${PREFIX}-customSuccess`]: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: successColor[8]
        },
        "&.Mui-focused": {
            color: successColor[8]
        }
    },
    [`& .${PREFIX}-customInfo`]: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: infoColor[8]
        },
        "&.Mui-focused": {
            color: infoColor[8]
        }
    },
    [`& .${PREFIX}-custom`]: {
        "&:hover:not($disabled):before,&:before": {
            borderBottomColor: grayColor[11] + " !important",
            borderBottomWidth: "1px !important"
        },
        "&:after": {
            borderBottomColor: theme.palette.custom.main
        },
        "&.Mui-focused": {
            color: theme.palette.custom.main
        },
        "&.MuiFormLabel-root": {
            top: "14px",
            left: "10px",
            "&.MuiFormLabel-filled, &.Mui-focused": {
                left: "0px",
                top: "8px"
            }
        },
        "& .MuiInput-input": {
            backgroundColor: whiteColor,
            padding: "10px",
        }
    },
});

let classes = {
    disabled: `${PREFIX}-disabled`,
    formControlDisabled: `${PREFIX}-formControlDisabled`,
    formControlReadonly: `${PREFIX}-formControlReadonly`,
    underline: `${PREFIX}-underline`,
    underlineError: `${PREFIX}-underlineError`,
    underlineSuccess: `${PREFIX}-underlineSuccess`,
    labelRoot: `${PREFIX}-labelRoot`,
    labelRootError: `${PREFIX}-labelRootError`,
    labelRootSuccess: `${PREFIX}-labelRootSuccess`,
    feedback: `${PREFIX}-feedback`,
    formControl: `${PREFIX}-formControl`,
    whiteUnderline: `${PREFIX}-whiteUnderline`,
    input: `${PREFIX}-input`,
    whiteInput: `${PREFIX}-whiteInput`,
    animate: `${PREFIX}-animate`,
    cancelIcon: `${PREFIX}-cancelIcon`,
    hasClearIcon: `${PREFIX}-hasClearIcon`,
    hasEndAdornment: `${PREFIX}-hasEndAdornment`,
    hasClearEndAdornment: `${PREFIX}-hasClearEndAdornment`,
    primary: `${PREFIX}-primary`,
    rose: `${PREFIX}-rose`,
    success: `${PREFIX}-success`,
    info: `${PREFIX}-info`,
    gray: `${PREFIX}-gray`,
    customRose: `${PREFIX}-customRose`,
    customSuccess: `${PREFIX}-customSuccess`,
    customInfo: `${PREFIX}-customInfo`,
    custom: `${PREFIX}-custom`,
};
export { customInputStyle, classes };
  
  export default customInputStyle;
  