/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid
} from "../../material-dashboard-pro-react.jsx";

const PREFIX = 'RouterComponent';
const adminStyle = theme => ({
  [`& .${PREFIX}-wrapper`]: {
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    }
  },
  [`& .${PREFIX}-mainPanel`]: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  [`& .${PREFIX}-content`]: {
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)"
  },
  [`& .${PREFIX}-container`]: {
    ...containerFluid,
  },
  [`& .${PREFIX}-map`]: {
    marginTop: "70px"
  },
  [`& .${PREFIX}-mainPanelSidebarMini`]: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }
  },
  [`& .${PREFIX}-mainPanelWithPerfectScrollbar`]: {
    overflow: "hidden !important"
  }
});

let classes = {
    wrapper: `${PREFIX}-wrapper`,
    mainPanel: `${PREFIX}-mainPanel`,
    content: `${PREFIX}-content`,
    container: `${PREFIX}-container`,
    map: `${PREFIX}-map`,
    mainPanelSidebarMini: `${PREFIX}-mainPanelSidebarMini`,
    mainPanelWithPerfectScrollbar: `${PREFIX}-mainPanelWithPerfectScrollbar`
};
export { adminStyle, classes };

export default adminStyle;
