import React from 'react'
import PropTypes from "prop-types";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import LoaderComponent from "../Loader";
import Button from "../CustomButtons/Button.jsx";
import DialogActions from "@mui/material/DialogActions";

// @mui/icons-material
import Close from "@mui/icons-material/Close";
import { styled } from '@mui/material/styles';
import { confirmationModalStyle, classes } from "../../../assets/jss/modals/confirmationModalStyle.jsx";
const ConfirmationModalRoot = styled(Dialog)(({ theme }) => confirmationModalStyle(theme));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ConfirmationModal = (props) => {
    const { message, showCloseIcon, saving, errorMessage } = props;
    return (
        <ConfirmationModalRoot
            classes={{
                root: classes.modalRoot,
                paper: classes.modal
            }}
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="confirmation-modal-slide-title"
            aria-describedby="confirmation-modal-slide-description"
        >
            {
                showCloseIcon ?
                    <DialogTitle
                        id="confirmation-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                        >
                            <Button
                                simple
                                className={classes.modalCloseButton+" "+classes.modalCloseButtonLogin}
                                key="close"
                                aria-label="Close"
                                onClick={() => props.onClose()}
                            >
                                {" "}
                                <Close className={classes.modalClose} />
                            </Button>
                    </DialogTitle>
                :
                null
            }
            <DialogContent
                id="confirmation-modal-slide-description"
                className={classes.modalBody}
                >
                    {
                        errorMessage !== null ?
                            <p className={classes.errorMessage}>{errorMessage}</p>
                        :
                            saving ?
                                <LoaderComponent align="center" color="success" />
                            :
                                <p>{message}</p>
                    }
                
            </DialogContent>
            {
                saving === false && errorMessage === null ?
                    <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                        <Button
                            onClick={() => props.onClose()}
                            color="white"
                        >
                            {props.cancelButtonText}
                        </Button>
                        {
                            props.showConfirmButton ?
                                <Button
                                    onClick={() => props.onSuccess()}
                                    color="success"
                                >
                                    {props.successButtonText}
                                </Button>
                            :
                            null
                        }
                    </DialogActions>
                :
                    null
            }
            
        </ConfirmationModalRoot>
    )
}

ConfirmationModal.defaultProps = {
    open: false,
    showCloseIcon: false,
    saving: false,
    errorMessage: null,
    cancelButtonText: "Cancel",
    successButtonText: "Yes",
    showConfirmButton: true
}
ConfirmationModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    message: PropTypes.string,
    saving: PropTypes.bool,
    showCloseIcon: PropTypes.bool,
    errorMessage: PropTypes.string
}
export default ConfirmationModal;