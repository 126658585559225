/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  grayColor
} from "../../material-kit-pro-react.jsx";

const PREFIX = 'Typography';
const typographyStyle = (theme) => ({
    [`&.${PREFIX}-defaultFontStyle`]: {
        ...defaultFont,
        fontSize: "14px"
    },
    [`&.${PREFIX}-defaultHeaderMargins`]: {
        marginTop: "20px",
        marginBottom: "10px"
    },
    [`&.${PREFIX}-quote`]: {
        padding: "10px 20px",
        margin: "0 0 20px",
        fontSize: "1.25rem",
        borderLeft: "5px solid " + grayColor[2]
    },
    [`&.${PREFIX}-quoteText`]: {
        margin: "0 0 10px",
        fontStyle: "italic"
    },
    [`&.${PREFIX}-quoteAuthor`]: {
        display: "block",
        fontSize: "80%",
        lineHeight: "1.42857143",
        color: grayColor[10]
    },
    [`&.${PREFIX}-mutedText`]: {
        "&, & *": {
        color: grayColor[7],
        display: "inline-block"
        }
    },
    [`&.${PREFIX}-primaryText`]: {
        "&, & *": {
        color: primaryColor[0],
        display: "inline-block"
        }
    },
    [`&.${PREFIX}-infoText`]: {
        "&, & *": {
        color: infoColor[0],
        display: "inline-block"
        }
    },
    [`&.${PREFIX}-successText`]: {
        "&, & *": {
        color: successColor[0],
        display: "inline-block"
        }
    },
    [`&.${PREFIX}-warningText`]: {
        "&, & *": {
        color: warningColor[0],
        display: "inline-block"
        }
    },
    [`&.${PREFIX}-dangerText`]: {
        "&, & *": {
        color: dangerColor[0],
        display: "inline-block"
        }
    },
    [`&.${PREFIX}-roseText`]: {
        "&, & *": {
        color: roseColor[0],
        display: "inline-block"
        }
    },
    [`&.${PREFIX}-smallText`]: {
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1",
        color: grayColor[10]
    }
});

let classes = {
    defaultFontStyle: `${PREFIX}-defaultFontStyle`,
    defaultHeaderMargins: `${PREFIX}-defaultHeaderMargins`,
    quote: `${PREFIX}-quote`,
    quoteText: `${PREFIX}-quoteText`,
    quoteAuthor: `${PREFIX}-quoteAuthor`,
    mutedText: `${PREFIX}-mutedText`,
    primaryText: `${PREFIX}-primaryText`,
    infoText: `${PREFIX}-infoText`,
    successText: `${PREFIX}-successText`,
    warningText: `${PREFIX}-warningText`,
    dangerText: `${PREFIX}-dangerText`,
    roseText: `${PREFIX}-roseText`,
    smallText: `${PREFIX}-smallText`
};
export { typographyStyle, classes };

export default typographyStyle;
