const PREFIX = 'App';
const appStyle = theme => ({
    [`&.${PREFIX}-mainApp`]: {
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundImage: "none !important",
        "&.layout_page_home": {
            "& #global_content": {
                paddingTop: "0px"
            }
        },
    },
});

const classes = {
    'root': `${PREFIX}-mainApp`
};

export { appStyle, classes };

export default appStyle;