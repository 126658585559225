/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui/icons-material
import PlaylistAddCheck from "@mui/icons-material/PlaylistAddCheck";
import Lock from "@mui/icons-material/Lock";
import SupervisedUserCircle from "@mui/icons-material/SupervisedUserCircle";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { mdiLogout, mdiLogin } from '@mdi/js';
import Config from "./Config";
const isHostedAuthEnabled = Config.isHostedAuthEnabled();

var dashRoutes = [
    {
        path: isHostedAuthEnabled ? Config.getLoginUrl("/login") : "/login",
        name: "Login",
        rtlName: "Login",
        iconPath: mdiLogin,
        // component: NotFound,
        layout: "",
        authRequired: false,
        isExternal: isHostedAuthEnabled
    },
    {
        path: isHostedAuthEnabled ? Config.getSignupUrl("/signup") : "/signup",
        name: "Signup",
        rtlName: "Signup",
        icon: AccountCircle,
        // component: NotFound,
        layout: "",
        authRequired: false,
        isExternal: isHostedAuthEnabled
    },
    {
        path: "/",
        name: "Home",
        rtlName: "Home",
        icon: PlaylistAddCheck,
        // component: NotFound,
        layout: "",
        authRequired: true
    },
    {
        path: "/salesforce/authorization",
        name: "Salesforce Auth",
        rtlName: "Salesforce Auth",
        icon: Lock,
        // component: NotFound,
        layout: "",
        authRequired: true
    },
    // {
    //     path: "/streaming-api",
    //     name: "Connect Streaming Api",
    //     rtlName: "Connect Streaming Api",
    //     icon: Lock,
    //     // component: NotFound,
    //     layout: "",
    //     authRequired: true
    // },
    {
        path: "/users",
        name: "Users",
        rtlName: "Users",
        icon: SupervisedUserCircle,
        // component: NotFound,
        layout: "",
        authRequired: true,
        authLevel: "admin"
    },
    {
        path: isHostedAuthEnabled ? Config.getLogoutUrl("/logout") : "/logout",
        name: "Logout",
        rtlName: "Logout",
        iconPath: mdiLogout,
        // component: NotFound,
        layout: "",
        authRequired: true,
        isExternal: isHostedAuthEnabled
    },
];
export default dashRoutes;
