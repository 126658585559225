import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Navigate } from 'react-router-dom';
import LoaderComponent from '../../components/Loader'
import AuthApi from '../../../assets/js/utils/Auth'
import Api from '../../../assets/js/utils/Api'
import { createBrowserHistory } from 'history'
import { mapStateToProps } from "../../../store/reducers/rootReducer";
const history = createBrowserHistory();

const Logout = (props) => {
    const { authorized } = props.state;
    useEffect(() => {
        if(authorized){
            const { dispatch, state } = props;
            var requestData = [];
            const { user } = state;
            requestData.push({
                'activityType': "logged_out",
                'userId': user.userId
            });
            Api.sendActivity(requestData);
            AuthApi.logout({
                logout: function(){
                    
                }   
            });
            history.push('/');
            dispatch({type: 'LOGOUT', state: {notifications: null}});
            props.history.push("/");
        }
    });
    if(!authorized){
        return <Navigate to="/" />
    }
    return (
        <LoaderComponent />
    )
}

export default connect(mapStateToProps)(Logout);
