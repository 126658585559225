const PREFIX = 'SFAuth';
const sfAuthPageStyle = theme => ({
    [`&.${PREFIX}-main`]: {
        "& p": {
            marginBottom: "10px"
        },
        "& span": {
            marginRight: "10px"
        },
        "& a": {
            display: "inline-block"
        }
        
    }
});

let classes = {
    main: `${PREFIX}-main`
};
export { sfAuthPageStyle, classes };

export default sfAuthPageStyle;
