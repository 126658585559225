// import React from "react";
// import { CometD } from "cometd";

// const cometd = new CometD();
// cometd.websocketEnabled = false;
// cometd.configure({
//     url: "https://ruby-flow-5025-dev-ed--c.visualforce.com/cometd/40.0/",
//     requestHeaders: {
//         "Authorization": "OAuth 00DJ00000042Jh7!AQsAQK4EHGDoYzkW2iIBbRbeM2OAleoWiB2kWeeKt6488LpkHEboXZeBmTvmEqJyAEUD9xXHZQQZd1WYI3YeA3tTaVdJGghg"
//     },
//     appendMessageTypeToURL: false
// });
const StreamingApi = (props) => {

    // cometd.handshake();
    
    // console.log(cometd);
    return null;
};

export default StreamingApi;