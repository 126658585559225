import React from "react";
// nodejs library to set properties for components

import Tabs from '@salesforce/design-system-react/components/tabs'; 
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import Button from '@salesforce/design-system-react/components/button';
import Icon from '@salesforce/design-system-react/components/icon';

import GlobalNavigationBar from '@salesforce/design-system-react/components/global-navigation-bar'; 
import GlobalNavigationBarRegion from '@salesforce/design-system-react/components/global-navigation-bar/region';
import GlobalNavigationBarDropdown from '@salesforce/design-system-react/components/global-navigation-bar/dropdown';
import GlobalNavigationBarLink from '@salesforce/design-system-react/components/global-navigation-bar/link';


import AppLauncher from '@salesforce/design-system-react/components/app-launcher';
import AppLauncherExpandableSection from '@salesforce/design-system-react/components/app-launcher/expandable-section';
import AppLauncherTile from '@salesforce/design-system-react/components/app-launcher/tile';

const Salesforce = (props) => {
    const dropdownCollection = [
        {
            label: 'Menu Item One',
            value: '1',
            iconCategory: 'utility',
            iconName: 'table',
            href: 'http://www.google.com',
        },
        {
            label: 'Menu Item Two',
            value: '2',
            iconCategory: 'utility',
            iconName: 'kanban',
            href: 'http://www.google.com',
        },
        {
            label: 'Menu Item Three',
            value: '3',
            iconCategory: 'utility',
            iconName: 'side_list',
            href: 'http://www.google.com',
        },
    ];
    return (
        <div>
            <Tabs id="tabs-example-scoped">
                <TabsPanel label="Item One">Item One Content</TabsPanel>
                <TabsPanel label="Item Two">Item Two Content</TabsPanel>
                <TabsPanel label="Item Three">Item Three Content</TabsPanel>
            </Tabs>

            <div className="slds-x-small-buttons_horizontal">
                <Button label="Brand" variant="brand" />

                <Button
                    disabled
                    label="Disabled"
                    onClick={() => {
                        console.log('Disabled Button Clicked');
                    }}
                    variant="brand"
                />

                <Button label="Destructive" variant="destructive" />

                <Button label="Outline Brand" variant="outline-brand" />

                <div
                    style={{
                        backgroundColor: '#16325c',
                        padding: '10px',
                        marginLeft: '5px',
                        display: 'inline-block',
                    }}
                    className="-m-horizontal--small"
                >
                    <Button inverse label="Inverse" variant="base" />
                </div>
            </div>

            <div className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
                <div className="slds-col_padded">
                    <Icon
                        assistiveText={{ label: 'Account' }}
                        category="standard"
                        name="account"
                        size="small"
                    />
                </div>
                <div className="slds-col_padded">
                    <Icon
                        assistiveText={{ label: 'Announcement' }}
                        category="utility"
                        name="announcement"
                        size="small"
                    />
                </div>
                <div className="slds-col_padded">
                    <Icon
                        assistiveText={{ label: 'Description' }}
                        category="action"
                        name="description"
                        size="small"
                    />
                </div>
                <div className="slds-col_padded">
                    <Icon
                        assistiveText={{ label: 'XML' }}
                        category="doctype"
                        name="xml"
                        size="small"
                    />
                </div>
                <div className="slds-col_padded">
                    <Icon
                        assistiveText={{ label: 'custom5' }}
                        category="custom"
                        name="custom5"
                        size="small"
                    />
                </div>
            </div>

            <GlobalNavigationBar>
                <GlobalNavigationBarRegion region="primary">
                    <AppLauncher
                        id="app-launcher-trigger"
                        triggerName="App Name"
                        onSearch={() => {
                            console.log('Search term:');
                        }}
                        modalHeaderButton={<Button label="App Exchange" />}
                    >
                        <AppLauncherExpandableSection title="Tile Section">
                            <AppLauncherTile
                                title="Marketing Cloud"
                                iconText="MC"
                                description="Send emails, track emails, read emails! Emails!"
                            />
                            <AppLauncherTile
                                title="Call Center"
                                description="The key to call center and contact center is not to use too many words!"
                                descriptionHeading="Call Center"
                                iconText="CC"
                            />
                        </AppLauncherExpandableSection>
                        <AppLauncherExpandableSection title="Small Tile Section">
                            <AppLauncherTile
                                title="Journey Builder"
                                iconText="JB"
                                size="small"
                            />
                            <AppLauncherTile
                                title="Sales Cloud"
                                iconNode={
                                    <Icon name="campaign" category="standard" size="large" />
                                }
                                size="small"
                            />
                        </AppLauncherExpandableSection>
                    </AppLauncher>
                </GlobalNavigationBarRegion>
                <GlobalNavigationBarRegion region="secondary" navigation>
                    <GlobalNavigationBarLink active label="Home" id="home-link" />
                    <GlobalNavigationBarDropdown
                        assistiveText={{ icon: 'Open menu item submenu' }}
                        id="primaryDropdown"
                        label="Menu Item"
                        options={dropdownCollection}
                    />
                    <GlobalNavigationBarLink label="Menu Item" />
                    <GlobalNavigationBarLink label="Menu Item" />
                    <GlobalNavigationBarLink label="Menu Item" />
                </GlobalNavigationBarRegion>
            </GlobalNavigationBar>
        </div>
    )
};

export default Salesforce;
