/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  primaryColor,
  grayColor,
  container,
  cardTitle,
  whiteColor,
  blackColor,
  hexToRgb,
  dangerColor
} from "../../material-kit-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "../customCheckboxRadioSwitchStyle.jsx";
const PREFIX = 'Signup';
const signupPageStyle = theme => ({
    [`& .${PREFIX}-container`]: {
        ...container,
        zIndex: "2",
        position: "relative",
        paddingTop: "0px",
        color: whiteColor,
        [theme.breakpoints.down("sm")]: {
            paddingTop: "20px",
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop: "20px",
        }
    },
    [`&.${PREFIX}-pageHeader`]: {
        minHeight: "100vh",
        height: "auto",
        display: "inherit",
        position: "relative",
        margin: "0",
        padding: "30px 0px",
        border: "0",
        alignItems: "center",
        "&:before": {
            // background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
        },
        "&:after": {
            // background:
            //     "linear-gradient(60deg,rgba(" +
            //     hexToRgb(primaryColor[4]) +
            //     ",.56),rgba(" +
            //     hexToRgb(primaryColor[5]) +
            //     ",.95))"
        },
        "&:before,&:after": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: '""'
        },
        [theme.breakpoints.down("sm")]: {
            padding: "80px 0px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "60px 0px",
        },
        //background: "#E2E2E1 !important"
    },
    [`& .${PREFIX}-cardSignup`]: {
        borderRadius: "6px",
        boxShadow:
        "0 16px 24px 2px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 6px 30px 5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2);",
        marginBottom: "100px",
        padding: "40px 0px",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "80px",
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: "50px",
        }
    },
    [`& .${PREFIX}-signupCard`]: {
        marginTop: "0px"
    },
    [`& .${PREFIX}-cardTitle`]: {
        ...cardTitle,
        textDecoration: "none",
        textAlign: "center !important",
        marginBottom: "0px !important",
        marginTop: "20px !important",
        display: "block"
    },
    ...customCheckboxRadioSwitchStyle,
    [`& .${PREFIX}-socials`]: {
        marginTop: "0",
        position: "absolute",
        width: "100%",
        transform: "none",
        left: "0",
        top: "0",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px"
    },
    [`& .${PREFIX}-textCenter`]: {
        textAlign: "center"
    },
    [`& .${PREFIX}-inputAdornment`]: {
        marginRight: "8px",
        position: "relative"
    },
    [`& .${PREFIX}-inputAdornmentIcon`]: {
        color: grayColor[13]
    },
    [`& .${PREFIX}-form`]: {
        marginBottom: "20px",
        overflow: "hidden",
        position: "relative"
    },
    [`& .${PREFIX}-infoArea`]: {
        padding: "0px 0px 20px !important"
    },
    [`& .${PREFIX}-block`]: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    [`& .${PREFIX}-inlineBlock`]: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    [`& .${PREFIX}-list`]: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    [`& .${PREFIX}-left`]: {
        float: "left!important",
        display: "block",
        "&,& *,& *:hover,& *:focus": {
        color: whiteColor + "  !important"
        }
    },
    [`& .${PREFIX}-right`]: {
        padding: "15px 0",
        margin: "0",
        float: "right",
        "&,& *,& *:hover,& *:focus": {
        color: whiteColor + "  !important"
        }
    },
    [`& .${PREFIX}-icon`]: {
        width: "18px",
        height: "18px",
        top: "3px",
        position: "relative"
    },
    [`& .${PREFIX}-terms`]: {
        marginTop: "0px",
        marginBottom: theme.spacing(2),
        color: dangerColor[0]
    },
    [`& .${PREFIX}-anchor`]: {
        color: primaryColor[0],
        cursor: "pointer"
    },
    [`& .${PREFIX}-verifyError`]: {
        marginTop: theme.spacing(1)
    },
    [`& .${PREFIX}-modalRootCustom`]: {
        padding: "0px",
        [theme.breakpoints.down('xs')]: {
            top: "16px !important",
        }
    },
    [`& .${PREFIX}-modalSignup`]: {
        width: "100%",
        maxWidth: "900px",
        [theme.breakpoints.down('xs')]: {
            marginTop: "0px !important"
        }        
    },
    [`& .${PREFIX}-paperScrollPaperCustom`]: {
        height: "auto",
    },
    [`& .${PREFIX}-modalSignupCard`]: {
        margin: "0px",
        padding: "40px 0px",
        [theme.breakpoints.down('xs')]: {
            paddingTop: "0px !important"
        } 
    },
    [`& .${PREFIX}-removeMargin`]: {
        marginBottom: "5px"
    },
    [`& .${PREFIX}-botFields`]: {
        display: "none !important"
    },
    [`& .${PREFIX}-socialLinks`]: {
        marginTop: theme.spacing(2),
        "& a": {
            display: "inline-block"
        },
        "& button": {
            width: "300px",
            height: "42px",
            padding: "0px 1px",
            textTransform: "none",
            "& .MuiButton-label": {
                height: "42px",
                justifyContent: "start",
                padding: "1px 0px"
            },
            [theme.breakpoints.down("xs")]: {
                width: "270px"
            }
        },
        "& svg": {
            display: "inline-block",
            height: "100%",
            width: "36px",
            marginRight: "6px",
            padding: "6px",
            textAlign: "center",
            verticalAlign: "middle",
            "&.google-icon": {
                background: whiteColor,
                borderRadius: "2px"
            }
        }
    },
    [`& .${PREFIX}-googleButton`]: {
        backgroundColor: "#4285F4",
        boxShadow:
            "0 2px 2px 0 rgba(" +
            hexToRgb("#4285F4") +
            ", 0.14), 0 3px 1px -2px rgba(" +
            hexToRgb("#4285F4") +
            ", 0.2), 0 1px 5px 0 rgba(" +
            hexToRgb("#4285F4") +
            ", 0.12)",
        "&:hover,&:focus": {
            backgroundColor: "#4285F4",
            boxShadow:
                "0 14px 26px -12px rgba(" +
                hexToRgb("#4285F4") +
                ", 0.42), 0 4px 23px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 8px 10px -5px rgba(" +
                hexToRgb("#4285F4") +
                ", 0.2)"
        }
    },
    [`& .${PREFIX}-recaptcha`]: {
        position: "absolute",
        visibility: "hidden",
        left: "0px",
        right: "0px",
        width: "100%",
        background: whiteColor,
        height: "230px",
        zIndex: "1",
        "& .g-recaptcha > div": {
            margin: "0px auto"
        },
    },
    [`& .${PREFIX}-showRecaptcha`]: {
        visibility: "visible",
    }
});

let classes = {
    container: `${PREFIX}-container`,
    pageHeader: `${PREFIX}-pageHeader`,
    cardSignup: `${PREFIX}-cardSignup`,
    signupCard: `${PREFIX}-signupCard`,
    cardTitle: `${PREFIX}-cardTitle`,
    socials: `${PREFIX}-socials`,
    textCenter: `${PREFIX}-textCenter`,
    inputAdornment: `${PREFIX}-inputAdornment`,
    inputAdornmentIcon: `${PREFIX}-inputAdornmentIcon`,
    form: `${PREFIX}-form`,
    infoArea: `${PREFIX}-infoArea`,
    block: `${PREFIX}-block`,
    inlineBlock: `${PREFIX}-inlineBlock`,
    list: `${PREFIX}-list`,
    left: `${PREFIX}-left`,
    right: `${PREFIX}-right`,
    icon: `${PREFIX}-icon`,
    terms: `${PREFIX}-terms`,
    anchor: `${PREFIX}-anchor`,
    verifyError: `${PREFIX}-verifyError`,
    modalRootCustom:`${PREFIX}-modalRootCustom`,
    modalSignup: `${PREFIX}-modalSignup`,
    paperScrollPaperCustom: `${PREFIX}-paperScrollPaperCustom`,
    modalSignupCard: `${PREFIX}-modalSignupCard`,
    removeMargin: `${PREFIX}-removeMargin`,
    botFields: `${PREFIX}-botFields`,
    socialLinks: `${PREFIX}-socialLinks`,
    googleButton: `${PREFIX}-googleButton`,
    recaptcha: `${PREFIX}-recaptcha`,
    showRecaptcha: `${PREFIX}-showRecaptcha`
};
export { signupPageStyle, classes };

export default signupPageStyle;
