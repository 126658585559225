import React from 'react';
import PropTypes from "prop-types";
import CircularProgress from '@mui/material/CircularProgress';
import classNames from "classnames";
import { styled } from '@mui/material/styles';
import { loaderStyle, classes } from "../../assets/jss/loaderStyle";
const LoaderRoot = styled('div')(({ theme }) => loaderStyle(theme));

class Loader extends React.Component {
    render(){
        const { align, saving } = this.props;
        let color = this.props.color;
        if(typeof(color) === "string" && color.indexOf("#") !== -1){
            color = "custom";
        }
        const loaderClasses = classNames({
            [classes[color]]: color,
            [classes.saving]: saving
        });
        return (
            <LoaderRoot className={"sd_loader align_"+align+" "+loaderClasses}>
                <CircularProgress size={30} color="inherit"/>
            </LoaderRoot>
        )
    }
}

Loader.defaultProps = {
    align: "center",
    color: "primary",
    saving: false
}
Loader.propTypes = {
    align: PropTypes.string,
    // color: PropTypes.oneOf([
    //     "primary",
    //     "secondary",
    //     "info",
    //     "success",
    //     "rose",
    //     "gray",
    //     "customInfo",
    //     "customSuccess",
    //     "customRose",
    //     "white",
    // ]),
    classes: PropTypes.object,
    saving: PropTypes.bool
};

export default Loader;